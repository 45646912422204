import '@babel//polyfill';
import 'isomorphic-fetch';

import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {setprototypeof} from 'setprototypeof';

import ChangePassword from './components/ChangePassword';
import Forgotpass from './components/Forgotpass';
import Login from './components/Login';
import Logout from './components/Logout';
import NotFound from './components/NotFound';
import Setpassword from './components/Setpassword';
import Signup from './components/SignUp';
import WriteReview from './components/WriteReview';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Sitemap from './components/Sitemap';
// import ReactPixel from 'react-facebook-pixel';
import ExitPrep from './components/EditProfile/tabs/exit_prep';
import EditProfileNew from './components/EditProfile/index';
import Premium from './components/EditProfile/tabs/premium';
import Console from './components/Console';
import Subscription from './containers/subscription';
import AdvancedSearch from './containers/advanced-search';
import Setup from './containers/setup';
import ProfilePage from './containers/ProfilePage';
import SetupForIntermediaries from './containers/setup-for-intermediaries';
import Completed from './containers/completed';

import Unsubscribe from './components/thank-you/unsubscribe';

import {ToastContainer} from 'react-toastify';

import Home from './containers/home';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider} from "./contexts/auth";
import RouteContainer from "./components/RouteContainer/RouteContainer";
import {USER_TYPE} from "./constants/userTypes";
import {SubscriptionLevels} from "./constants/subscriptionLevels";
import {redirect, Navigate, Route, Routes} from "react-router";
import DealPage from "./containers/DealPage";


Object.setPrototypeOf = setprototypeof;
// eslint-disable-next-line
ReactGA.initialize(process.env.REACT_APP_GTAG_ID, {
    gaOptions: {
        debug_mode: true,
    },
    gtagOptions: {
        debug_mode: true,
    },
});

const CloseButton = ({closeToast}) => (
    <div style={{fontSize: 14}} onClick={closeToast}>Close {`   `}<i className="fa-solid fa-xmark"/></div>
);

const App = () => {
    useEffect(() => {
        if (process.env.REACT_APP_ROOT === 'https://app.biznexus.com/') {
            ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname + window.location.search,
                title: document.title
            });

            const advancedMatching = {em: 'go.stephendavis@gmail.com'};
            const options = {
                autoConfig: true,
                debug: false,
            };
            // ReactPixel.init('923862711099248', advancedMatching, options);
            //
            // ReactPixel.pageView();
            // ReactPixel.track('PageView');
        }

    }, [])

    return (<>
                <AuthProvider>
                    <Routes>

                        <Route exact path="/" element={<Navigate to="/home" />}/>

                        <Route exact path="/home" element={<RouteContainer
                            exact path="/home" component={Home} config={{
                            [USER_TYPE.BUYER]: {
                                allowed: true,
                                minSubscription: SubscriptionLevels.PREMIUM
                            },
                            [USER_TYPE.SELLER]: {
                                allowed: true,
                                minSubscription: SubscriptionLevels.PREMIUM
                            },
                            [USER_TYPE.PROVIDER]: {
                                allowed: true,
                                minSubscription: SubscriptionLevels.PREMIUM
                            },
                        }}
                            fallback="/user/subscription"
                        />}/>

                        <Route exact path="/completed-transactions" element={<RouteContainer exact path="/completed-transactions" component={Completed}/>}/>

                        <Route exact path="/logout" element={<Logout/>} />
                        <Route exact path="/login" element={<RouteContainer exact path="/login" component={Login}
                                        config={{loggedIn: false, approved: false, loggedOut: true}}/>}/>
                        <Route exact path="/signup/:referral?" element={<RouteContainer exact path="/signup/:referral?" component={Signup}
                                        config={{loggedIn: false, approved: false, loggedOut: true}}/>}/>
                        <Route exact path="/forgotpass" element={<RouteContainer exact path="/forgotpass" component={Forgotpass}
                                    config={{loggedIn: false, approved: false, loggedOut: true}}/>}/>
                        <Route exact path="/changepassword" element={<ChangePassword/>}/>
                        <Route exact path="/setpassword" element={<Setpassword/>}/>
                        <Route exact path="/linkedinSuccess" element={<LinkedInCallback/>}/>
                        <Route exact path="/sitemap" element={<Sitemap/>}/>
                        <Route exact path="/premium" element={<Premium/>}/>
                        <Route exact path="/exit-prep" element={<ExitPrep/>}/>

                            <Route exact path="/edit-profile/:tab?" element={<RouteContainer exact path="/edit-profile/:tab?" component={EditProfileNew}/>}/>
                            <Route exact path="/user/:tab?/:subtab?" element={<RouteContainer
                            exact
                            path="/user/:tab?/:subtab?"
                            component={EditProfileNew}
                            config={{
                                tabs: {
                                    profile: {loggedIn: true, approved: false},
                                    integrations: {
                                        [USER_TYPE.BUYER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.SELLER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.PROVIDER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        fallback: '/subscription'
                                    },
                                    messages: {
                                        [USER_TYPE.BUYER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.SELLER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.PROVIDER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        fallback: '/subscription'
                                    },
                                    listings: {
                                        subtabs: {
                                            "suggested_brokers": {
                                                [USER_TYPE.BROKER]: {
                                                    allowed: false,
                                                },
                                                [USER_TYPE.BUYER]: {
                                                    allowed: false,
                                                },
                                                [USER_TYPE.SELLER]: {
                                                    allowed: false,
                                                },
                                                [USER_TYPE.PROVIDER]: {
                                                    allowed: false,
                                                },
                                            },
                                            "suggested_buyers": {
                                                [USER_TYPE.BROKER]: {
                                                    allowed: true,
                                                },
                                                [USER_TYPE.BUYER]: {
                                                    allowed: false,
                                                },
                                                [USER_TYPE.SELLER]: {
                                                    allowed: false,
                                                },
                                                [USER_TYPE.PROVIDER]: {
                                                    allowed: false,
                                                },
                                            },
                                        }
                                    },
                                    favorites: {
                                        [USER_TYPE.BROKER]: {
                                            allowed: false,
                                        },
                                        [USER_TYPE.BUYER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.SELLER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        [USER_TYPE.PROVIDER]: {
                                            allowed: true,
                                            minSubscription: SubscriptionLevels.PREMIUM
                                        },
                                        fallback: '/subscription',
                                        subtabs: {
                                            preferences: {
                                                [USER_TYPE.BROKER]: {
                                                    allowed: true,
                                                },
                                                [USER_TYPE.BUYER]: {
                                                    allowed: true,
                                                    minSubscription: SubscriptionLevels.PREMIUM
                                                },
                                                [USER_TYPE.SELLER]: {
                                                    allowed: true,
                                                    minSubscription: SubscriptionLevels.PREMIUM
                                                },
                                                [USER_TYPE.PROVIDER]: {
                                                    allowed: true,
                                                    minSubscription: SubscriptionLevels.PREMIUM
                                                },
                                                fallback: '/subscription',
                                            }
                                        }
                                    }
                                }
                            }}
                        />}/>
                            <Route exact path="/search-results" element={<Navigate to="/advanced-search" replace={true} />}/>
                        <Route exact path="/business-for-sale/:location/:dealDetails/:dealId" element={<RouteContainer exact path="/business-for-sale/:location/:dealDetails/:dealId" component={DealPage}/>} />
                        <Route exact path="/business-for-sale/:dealDetails/:dealId" element={<RouteContainer exact path="/business-for-sale/:dealDetails/:dealId" component={DealPage}/>} />
                        <Route exact path="/business-for-sale/:dealId" element={<RouteContainer exact path="/business-for-sale/:dealId" component={DealPage}/>} />
                        <Route exact path="/business-broker/:location/:userName" element={<RouteContainer exact path="/business-broker/:location/:userName" component={ProfilePage}/>} />
                        <Route exact path="/business-broker/:userName" element={<RouteContainer exact path="/business-broker/:userName" component={ProfilePage}/>} />
                        <Route exact path="/profile/:userName" element={<RouteContainer exact path="/profile/:userName" component={ProfilePage}/>} />
                        <Route exact path="/profile/:location/:userName" element={<RouteContainer exact path="/profile/:location/:userName" component={ProfilePage}/>} />
                        {/*<Route exact path="/business-for-sale-in-:location" component={LocationSearchComponent} />*/}
                        {/*<Route exact path="/franchise-opportunity/:franchiseDetails/:franchiseId" component={FranchiseDetails} />*/}
                            <Route exact path="/reviews/:userName" element={<RouteContainer exact path="/reviews/:userName" component={WriteReview}/>}/>
                            <Route exact path="/reviews/:location/:userName" element={<RouteContainer exact path="/reviews/:location/:userName" component={WriteReview}/>}/>
                            <Route exact path="/console/:tab?/:id?" element={<RouteContainer exact path="/console/:tab?/:id?" component={Console}/>}/>
                            <Route exact path="/subscription" element={<RouteContainer
                            exact
                            path="/subscription"
                            component={Subscription}
                            config={{[USER_TYPE.BROKER]: {allowed: false}}}/>}/>
                            <Route exact path="/unsubscribe" element={<Unsubscribe/>}/>
                            <Route exact path="/advanced-search" element={<RouteContainer
                            exact
                            path="/advanced-search"
                            component={AdvancedSearch}
                            config={{
                                [USER_TYPE.BROKER]: {allowed: false},
                                [USER_TYPE.BUYER]: {
                                    allowed: true,
                                    minSubscription: SubscriptionLevels.PREMIUM,
                                    fallback: '/subscription'
                                },
                                [USER_TYPE.SELLER]: {
                                    allowed: true,
                                    minSubscription: SubscriptionLevels.PREMIUM,
                                    fallback: '/subscription'
                                },
                                [USER_TYPE.PROVIDER]: {
                                    allowed: true,
                                    minSubscription: SubscriptionLevels.PREMIUM,
                                    fallback: '/subscription'
                                }
                            }}
                        />}/>
                            <Route exact path="/setup" element={<RouteContainer exact path="/setup" component={Setup}
                                        config={{loggedIn: true, approved: false}}/>}/>
                            <Route exact path="/setup-for-intermediaries" element={<RouteContainer exact path="/setup-for-intermediaries" component={SetupForIntermediaries}
                                        config={{loggedIn: true, approved: false}}/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </AuthProvider>

            <ToastContainer closeButton={CloseButton}/>
        </>
    );
}


export default App;
