/* eslint-disable */
import React from 'react';
import api from '../../services/api';
import {Table, Button, UncontrolledTooltip} from 'reactstrap';
import moment from 'moment';
import {Link} from 'react-router-dom';

export const USER_TYPE_LABEL = {
    1: 'Buyer',
    2: 'Seller',
    3: 'M&A Advisor',
    4: 'Provider'
};

export const UserStatusMap = {
    '-1': {name: 'Ignored', color: 'gray'},
    '0': {name: 'Pending', color: 'yellow'},
    '1': {name: 'Approved', color: 'green'},
    '2': {name: 'Rejected', color: 'red'},
    '3': {name: 'Wants To Come Back', color: 'red'},
    '4': {name: 'Pending Wants To Come Back', color: 'yellow'},
}

export const UserStatusNameMap = {
    'Ignored': '-1',
    'Pending': '0',
    'Approved': '1',
    'Rejected': '2',
    'WantsComeBack': '3',
    'PendingWantsComeBack': '4',
}

export default class Signups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            buyers: [],
            wantsToComeBack: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        api.get('console/getBuyers', null, true).then(buyers => this.setState({buyers}));
        api.get('console/wantsToComeBack', null, true).then(wantsToComeBack => this.setState({wantsToComeBack}));
    }

    onApprove = id => {
        api.get('console/accept_user/' + id, null, true).then(() => {
            this.fetchData();
        });
    }

    onReject = id => {
        api.get('console/reject_user/' + id, null, true).then(() => {
            this.fetchData();
        });
    }

    onIgnore = id => {
        api.get('console/ignore_user/' + id, null, true).then(() => {
            this.fetchData();
        });
    }

    doLogin = email => {
        api.post('console/account', { email }, true).then(res => {
            if (res.success) {
                localStorage.setItem('admin_token', localStorage.getItem('token'));
                localStorage.setItem('back_to_console_signup', 'back_to_console_signup');
                localStorage.setItem('token', res.token);
                window.location.href = '/home';
            }
        })
    }

    render() {

        const getBackgroundColor = b => {
            return UserStatusMap[b.status].color
        }

        return (
            <div>
                <div className="container console-signups" style={{width: '100%', maxWidth: '100%'}}>
                    <Link to="/console">&larr; Back</Link>
                    <br/><br/>

                    {this.state.wantsToComeBack.length ?
                        <>
                            <h2>Buyers That Want to Come Back</h2>
                            <Table bordered hover>
                                <thead style={{textAlign: 'center'}}>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email / LinkedIn</th>
                                    <th>Location</th>
                                    <th>Company</th>
                                    <th>IP</th>
                                    <th>Login At</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.wantsToComeBack.map(b =>
                                    <tr style={{textAlign: 'center'}}>
                                        <td>{b.id}</td>
                                        <td>{b.name}</td>
                                        <td>{b.email}</td>
                                        <td style={{maxWidth: 200}}>{b.locationString || 'n/a'}</td>
                                        <td>{b.company ? b.company.firm_name.slice(0, 25) : 'n/a'}</td>
                                        <td>{b.creation_ip}</td>
                                        <td id={`review${b.id}`}>
                                            {
                                                b.last_login_at
                                                    ? <>
                                                        {moment(new Date(b.last_login_at)).fromNow()}
                                                        <UncontrolledTooltip placement="auto" target={`review${b.id}`}>
                                                            {moment(new Date(b.last_login_at)).format('LLL')}
                                                        </UncontrolledTooltip>
                                                    </>
                                                    : '-'
                                            }
                                        </td>
                                        <td style={{backgroundColor: getBackgroundColor(b)}}></td>
                                        <td>
                                            {b.status === UserStatusNameMap.PendingWantsComeBack &&
                                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                    <Button size="sm" color="success"
                                                            onClick={() => this.onApprove(b.id)}
                                                            id={`approve-btn-${b.id}`}>
                                                        <i className="fa fa-check" aria-hidden="true"/>
                                                    </Button>
                                                    <UncontrolledTooltip placement="auto"
                                                                         target={`approve-btn-${b.id}`}>
                                                        Approve
                                                    </UncontrolledTooltip>

                                                    <Button
                                                        style={{marginTop: 10}}
                                                        size="sm"
                                                        color="danger"
                                                        onClick={() => this.onReject(b.id)}
                                                        id={`reject-btn-${b.id}`}
                                                    >
                                                        <i className="fa fa-hand-paper-o" aria-hidden="true"/>
                                                    </Button>
                                                    <UncontrolledTooltip placement="auto" target={`reject-btn-${b.id}`}>
                                                        Reject
                                                    </UncontrolledTooltip>
                                                    {
                                                        b.status !== UserStatusNameMap.Ignored && <>
                                                            <Button style={{marginTop: 10}} size="sm"
                                                                    id={`ignore-btn-${b.id}`} color="secondary" outline
                                                                    onClick={() => this.onIgnore(b.id)}>
                                                                <i className="fa-solid fa-circle-pause"/>
                                                            </Button>

                                                            <UncontrolledTooltip placement="auto"
                                                                                 target={`ignore-btn-${b.id}`}>
                                                                Ignore
                                                            </UncontrolledTooltip>
                                                        </>
                                                    }
                                                </div>}
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        </>
                        : null}
                    <h3>User Signups</h3>
                    <Table responsive bordered hover>
                            <thead style={{textAlign: 'center'}}>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Email</th>
                                <th style={{maxWidth: 300}}>Search criteria</th>
                                <th>LinkedIn</th>
                                <th>Created At</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.buyers.map(b =>
                                <tr style={{textAlign: 'center'}}>
                                    <td>{b.id}</td>
                                    <td>{b.name}</td>
                                    <td style={{minWidth: 170}}>
                                        <button className="btn btn-tag is-active">{USER_TYPE_LABEL[b.user_type_id]}</button>
                                    </td>
                                    <td><a href="javascript:void(0)" onClick={() => this.doLogin(b.email)}>{b.email}</a></td>
                                    <td style={{maxWidth: 300}}>{b.reason_to_join || 'n/a'}</td>
                                    <td style={{width: '100%', wordBreak: 'break-word'}}><a target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                            href={b.linkedin_url}>{b.linkedin_url}</a>
                                    </td>
                                    <td id={`review${b.id}`}>
                                        {moment(new Date(b.created_at)).format('MM/DD/YYYY hh:mm:ss a')}
                                        <UncontrolledTooltip placement="auto" target={`review${b.id}`}>
                                            {moment(new Date(b.created_at)).format('LLL')}
                                        </UncontrolledTooltip>
                                    </td>
                                    <td style={{backgroundColor: getBackgroundColor(b)}}></td>
                                    <td>
                                        {Number(b.status) < 1 && <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <Button size="sm" color="success" onClick={() => this.onApprove(b.id)}
                                                    id={`approve-btn-${b.id}`}>
                                                <i className="fa fa-check" aria-hidden="true"/>
                                            </Button>
                                            <UncontrolledTooltip placement="auto" target={`approve-btn-${b.id}`}>
                                                Approve
                                            </UncontrolledTooltip>

                                            <Button
                                                style={{marginTop: 10}}
                                                size="sm"
                                                color="danger"
                                                onClick={() => this.onReject(b.id)}
                                                id={`reject-btn-${b.id}`}
                                            >
                                                <i className="fa fa-hand-paper-o" aria-hidden="true"/>
                                            </Button>
                                            <UncontrolledTooltip placement="auto" target={`reject-btn-${b.id}`}>
                                                Reject
                                            </UncontrolledTooltip>
                                            {
                                                b.status !== UserStatusNameMap.Ignored && <>
                                                    <Button style={{marginTop: 10}} size="sm" id={`ignore-btn-${b.id}`}
                                                            color="secondary" outline onClick={() => this.onIgnore(b.id)}>
                                                        <i className="fa-solid fa-circle-pause"/>
                                                    </Button>

                                                    <UncontrolledTooltip placement="auto" target={`ignore-btn-${b.id}`}>
                                                        Ignore
                                                    </UncontrolledTooltip>
                                                </>
                                            }
                                        </div>}
                                    </td>
                                </tr>
                            )}
                            </tbody>
            </Table>
                </div>
            </div>
        )
    }
}


