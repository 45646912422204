import React from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {Bars} from "react-loader-spinner";
import {USER_TYPE_LABEL, UserStatusMap} from "./signups";
import moment from "moment";
import Select from "react-select";
import {Col, Row} from "reactstrap";
import {getImageUrl} from "../util";
import {SubscriptionLevels} from "../../constants/subscriptionLevels";
import SubscriptionSelect from "./table/subscription";
import TypeSelect from "./table/type";
import {toast} from 'react-toastify';
import Note from "./table/note";
import StatusSelect from "./table/status";
import {debounce, throttle} from "lodash";

export default class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      totalRow: 0,
      loading: false,
      perPage: 25,
      sort: 'id',
      order: 'desc',
      search: '',
      filterUserType: null,
      filterUserStatus: null,
      filterLevel: null,
      level: null,
    };
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  }

  setPerPage = (value) => {
    this.setState({ perPage: value });
  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.fetchUsers(1);
  }

  fetchUsers = async (page = 1, perPage = 25) => {
    const { sort, order, search, filterUserType, filterUserStatus, filterLevel } = this.state;
    const filter = {};
    if(search)           filter.search = search;
    if(filterUserType)   filter.type = filterUserType.value;
    if(filterUserStatus) filter.status = filterUserStatus.value;
    if(filterLevel)      filter.level = filterLevel.value;
    const params = Object.keys(filter).map(key => key + '=' + filter[key]).join('&');

    this.setLoading(true);

    await api.get(`console/table/users?page=${page}&per_page=${perPage}&sort=${sort}&order=${order}&${params}`, null, true).then(users => {
      this.setState({ list: users.list, totalRow: users.totalRow });
    });

    this.setLoading(false);
  }

  handlePageChange = page => {
    this.fetchUsers(page);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setPerPage(newPerPage);
    this.fetchUsers(page, newPerPage);
  };

  handleSort = async (column, sortDirection) => {
    this.setState({
      sort: column.key, order: sortDirection
    }, () => {
      this.fetchUsers();
    })
  };
  throttledSearch = debounce(this.fetchUsers, 500)

  onChangeSearch = e => {
    this.setState({ search: e.target.value, loading: true }, () => {
      this.throttledSearch()
    })
  }

  onChangeSubscription = (email, data, cb) => {
    this.setLoading(true);
    api.post('console/add_premium', { email, ...data }, true).then(res => {
      if(res.success) {
        this.fetchUsers();
      } else {
        alert(res.message);
      }
      cb ? cb() : null
    });
  }

  onChangeType = (id, type) => {
    this.setLoading(true);
    api.post('console/change_user_type', { id, type }, true).then(res => {
      this.fetchUsers();
    });
  }

  onChangeStatus = (id, status) => {
    this.setLoading(true);
    api.post('console/change_user_status', { id, status }, true).then(res => {
      this.fetchUsers();
    });
  }

  loginAs = email => {
    api.post('console/account', { email }, true).then(res => {
      if (res.success) {
        localStorage.setItem('admin_token', localStorage.getItem('token'));
        localStorage.setItem('token', res.token);
        window.location.href = '/home';
      }
    })
  }

  onChangeNote = (user, note) => {
    api.post('console/set_internal_note', { id: user.id, note }, true);
    toast.info("Success", {
      position: "bottom-right",
      className: 'toast-bar',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }


  render() {

    const columns = [
      { name: 'ID', key: 'id', sortable: true, selector: row => row.id },
      { name: 'Name', key: 'name', sortable: true, selector: row => row.name },
      { name: 'Email', key: 'email', sortable: true, selector: row => row.email },
      { name: 'Type', selector: row => row.user_type_id, cell: (row) => {
        return <button className="btn btn-tag is-active">{USER_TYPE_LABEL[row.user_type_id]}</button>
      }},
      { name: 'Status', selector: row => row.status, cell: (row) => {
          return <button style={{ backgroundColor: UserStatusMap[row.status].color.replace('yellow', '#FFBF00') }} className="btn btn-tag is-active">{UserStatusMap[row.status].name}</button>
      }},
      { name: 'Joined At', key: 'created_at', sortable: true, selector: row => row.created_at, cell: (row) => {
          return moment(new Date(row.created_at)).format('MM/DD/YYYY hh:mm:ss a')
        }},
    ];

    const userTypeLevels = Object.keys(USER_TYPE_LABEL);
    const userStatus = Object.keys(UserStatusMap);
    const levels = Object.keys(SubscriptionLevels);

    return (
        <div>
          <Link to="/console">&larr; Back</Link>
          <br /><br /><br />
          <DataTable
              columns={columns}
              progressPending={this.state.loading}
              paginationServer
              pagination
              paginationTotalRows={this.state.totalRow}
              paginationPerPage={25}
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              onSort={this.handleSort}
              sortServer
              highlightOnHover
              pointerOnHover
              data={this.state.list}
              persistTableHead
              expandableRows
              expandOnRowClicked
              expandableRowsComponent={({data}) =>
                <div style={{ padding: 25 }}>
                  <div className="ms-2">
                    <Row>
                      <Col sm="2" className="text-center">
                        <img
                            src={(data.profile_pic) ?
                                (data.profile_pic.indexOf('base64') > -1) ? data.profile_pic : (data.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(data.profile_pic) : getImageUrl(data.profile_pic) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                            alt="" width="100" height="100"
                            onError={
                              (e) => {
                                //@ts-ignore
                                if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                  e.target.src = `/images/thumbnail-small.gif`;
                                }
                              }
                            }
                            className="img-fluid rounded-circle z-depth-0" />

                        <h2 className="h2 mt-2 mb-2">{data.name}</h2>
                        <br />
                        <button onClick={() => this.loginAs(data.email)} className="btn btn-primary btn-sm">Log into account</button>
                        <br />
                        <a href={`/profile/${data.id}`} target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 btn-sm">BizNexus Profile</a>

                        <br />
                        <a target="_blank" className="btn btn-primary mt-2 btn-sm"
                           rel="noopener noreferrer"
                           href={data.linkedin_url}>LinkedIn</a>
                        <br />
                        {data.premium_level === SubscriptionLevels.CONCIERGE && <a href={`/console/concierge-links/${data.id}`} className="btn btn-primary mt-1 btn-sm">Concierge Links</a>}
                      </Col>
                      <Col sm="3">
                        <div>
                          <div className="font-weight-bold">Email:</div>
                          <div>
                            <a href={`mailto:${data.email}`}>{data.email}</a>
                          </div>
                        </div>
                        <br />
                        <div>
                          <div className="font-weight-bold">Search criteria:</div>
                          <div>
                            {data.reason_to_join}
                          </div>
                        </div>
                      </Col>
                      <Col sm="3">
                        <SubscriptionSelect user={data} onChangeSubscription={this.onChangeSubscription} />
                        <Note user={data} onChangeNote={this.onChangeNote} />
                      </Col>
                      <Col sm="3">
                        <TypeSelect user={data} onChangeType={this.onChangeType} />
                        <StatusSelect user={data} onChangeStatus={this.onChangeStatus} />
                      </Col>
                    </Row>

                  </div>
                </div>
              }
              subHeader
              subHeaderComponent={
                <div className="row w-100">
                  <Col sm="5">
                    <input style={{ height: 36 }} placeholder="Search" className="form-control bg-white mt-2" type="text" value={this.state.search} onChange={this.onChangeSearch} />
                  </Col>
                  <Col sm="2">
                    <Select 
                        classNamePrefix="Select"
                        classNames={{
                            control: (state) =>
                                state.isFocused ? 'Select-control' : 'Select-control',
                        }}
                        className="mt-2"
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Subscription"
                        onChange={(option) => this.setState({ filterLevel: option }, () => this.fetchUsers())}
                        options={levels.map(l => ({ label: l, value: SubscriptionLevels[l] }))}
                        value={this.state.filterLevel}
                    />
                  </Col>
                  <Col sm="2">
                    <Select 
                        classNamePrefix="Select"
                        classNames={{
                            control: (state) =>
                                state.isFocused ? 'Select-control' : 'Select-control',
                        }}
                        className="mt-2"
                        isSearchable={false}
                        isClearable={false}
                        placeholder="User Type"
                        onChange={(option) => this.setState({ filterUserType: option }, () => this.fetchUsers())}
                        options={userTypeLevels.map(l => ({ label: USER_TYPE_LABEL[l], value: l }))}
                        value={this.state.filterUserType}
                    />
                  </Col>
                  <Col sm="2">
                    <Select 
                        classNamePrefix="Select"
                        classNames={{
                            control: (state) =>
                                state.isFocused ? 'Select-control' : 'Select-control',
                        }}
                        className="mt-2"
                        isSearchable={false}
                        isClearable={false}
                        placeholder="Status"
                        onChange={(option) => this.setState({ filterUserStatus: option }, () => this.fetchUsers())}
                        options={userStatus.map(l => ({ label: UserStatusMap[l].name, value: l }))}
                        value={this.state.filterUserStatus}
                    />
                  </Col>
                  <Col sm="1">
                    <a href={"/console/users"} className="mt-2 btn btn-primary btn-sm">Reset</a>
                  </Col>


                </div>
              }
              progressComponent={
                  <div style={{ padding: 100 }} className="text-center d-flex justify-content-center">
                    <Bars
                        color="#3890da"
                        height={100}
                        width={100}
                        visible={true}
                        ariaLabel="bars-loading"
                    />
                  </div>
              }
          />
        </div>
    )
  }
}
