/* eslint-disable */
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  Row,
  Input, FormGroup, Label,
} from 'reactstrap';
import {Typeahead} from "react-bootstrap-typeahead";
import api from "../services/api";
import {getImageUrl} from "../components/util";
import {find} from "lodash";
import moment from "moment";
import _ from "lodash";
import {Bars} from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";

export default class Completed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      fields: {
        searchbox: ''
      },
      year: null,
      minpricerangeselected: '',
      maxpricerangeselected: '',
      brokerIndustry: [],
      categorylist: [],
      loading: true,
      activePageInfinity: 1,
      staticNumberOfRow: 25,
      total: 0,
      totalPlaceholder: 0,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    api.get('transaction/completed', null, false).then(res => {
      this.setState({list: res.list, total: res.total, totalPlaceholder: res.total, loading: false});
    });

    fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      },
    }).then(res => res.json())
        .then(
            (res) => {
              let categorylistarray = [];
              let temp = {};
              // eslint-disable-next-line
              res.map((categories, i) => {
                temp = {
                  "value": categories.value,
                  "label": categories.label,
                  "children": categories.children,
                };
                categorylistarray.push(temp);
              });
              this.setState({ categorylist: categorylistarray });
            },
            (error) => {
              this.setState({
                //error
              });
            }
        );
  }

  handleChangeSearch(field, e) {
    if (e.target !== '' && e.target !== undefined) {
      let fields = this.state.fields;
      fields[field] = e.target.value;

      if (e.target.name === 'minpricerange') {
        let minpricerange = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (e.target.name === 'maxpricerange') {
        let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
        fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      this.setState({ fields });
    }
  }
  handleChangeMinPriceRange = (e) => {
    let fields = this.state.fields;
    fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, minpricerangeselected: e.target.value });
  }
  handleChangeMaxPriceRange = (e) => {
    let fields = this.state.fields;
    fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ fields, maxpricerangeselected: e.target.value });
  }

  onAddBrokerIndustry = item => {
    const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

    if( ! exist) {
      this.setState({
        brokerIndustry: this.state.brokerIndustry.concat(item)
      });
    }
  }

  checkedIndustry = item => {
    const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

    if(exist) {
      return (
          <i className="fa-solid fa-check"></i>
      )
    }
  }

  onRemoveBrokerIndustry = item => {
    const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
    this.setState({
      brokerIndustry
    });
  }

  cleanBrokerIndustry = () => {
    this.setState({ brokerIndustry: [] });
  }

  onFilter = (infinity = false) => {
    if(infinity && this.state.list.length === this.state.total) {
      return;
    }

    this.setState({ loading: true });
    let fields = this.state.fields;
    let minpricerangeselected, maxpricerangeselected = '';
    const categoryOption = this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value);
    if (fields["minpricerange"] !== undefined) {
      if (this.state.minpricerangeselected === "Any Min") {
        minpricerangeselected = '';
      }
      else {
        minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
      }
    }
    if (fields["maxpricerange"] !== undefined) {
      if (this.state.maxpricerangeselected === "Any Max") {
        maxpricerangeselected = '';
      }
      else {
        maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
      }
    }
    const q = {
      "industry_id": categoryOption || [],
      "price_min": minpricerangeselected,
      "price_max": maxpricerangeselected,
      "name": fields["searchbox"],
      "year": this.state.year,
      "page": infinity ? this.state.activePageInfinity : 1,
    }

    api.post('transaction/search_completed', q, false)
        .then(res => {
          this.setState({
            list: infinity ? this.state.list.concat(res.list) : res.list,
            total: res.total,
            totalPlaceholder: infinity ? this.state.totalPlaceholder : res.total,
            loading: false
          });
        })
  }

  clearMetrics = (a, b) => {
    let fields = this.state.fields;
    fields[a] = '';
    fields[b] = '';
    this.setState({ fields });
  }

  fetchMoreData = () => {
    this.setState({ activePageInfinity: this.state.activePageInfinity + 1 }, () => {
      this.onFilter(true);
    });

  }

  render() {
    const priceValues = {
      price: {
        min: +this.state.fields.minpricerange?.replace(/[^0-9]/g, ''),
        max: +this.state.fields.maxpricerange?.replace(/[^0-9]/g, ''),
      },
      revenue: {
        min: +this.state.fields.minrevenue?.replace(/[^0-9]/g, ''),
        max: +this.state.fields.maxrevenue?.replace(/[^0-9]/g, ''),
      },
      revenue_multiple: {
        min: +this.state.fields.minrevenue_multiple,
        max: +this.state.fields.maxrevenue_multiple,
      },
      ebitda_multiple: {
        min: +this.state.fields.minebitda_multiple,
        max: +this.state.fields.maxebitda_multiple,
      },
      cashflow_multiple: {
        min: +this.state.fields.mincashflow_multiple,
        max: +this.state.fields.maxcashflow_multiple,
      },
    }
    const isPriceValueAvailable = (priceType) => (value, isMin) => {
      if (isMin) {
        return !priceValues[priceType].max || priceValues[priceType].max > value
      }
      return !priceValues[priceType].min || priceValues[priceType].min < value
    }

    let years = [];
    let currentYear = moment().year();
    for (let year = currentYear; year >= currentYear - 10; year--) {
      years.push(year);
    }
    console.log(this.state.brokerIndustry);

    return (
        <>
          <Header />

          <div style={{ height: 350, backgroundColor: '#474747' }}></div>

          <div className="container">
            <div className="loader" id="loader" style={{ display: "none" }}>
              <div className="spiner"></div>
            </div>

            <div style={{ paddingTop: "30px", paddingBottom: "50px", position: 'relative', top: -350, }}>
              <h1 style={{ color: 'white' }}>Completed Transactions</h1>


              <div style={{ paddingTop: "50px" }}>
                <Row className="align-items-center row-gutter-10 search_console_mobile homepage" style={{ zIndex: 99999 }}>
                  <div className="col position-relative">
                    <Typeahead
                        multiple
                        allowNew
                        id="searchbox"
                        className={`btn-filter-completed-keywords btn-filter-completed w-100 p-0 ${this.state.fields.searchbox && 'active'}`}
                        newSelectionPrefix="Add a keyword"
                        name="searchbox"
                        maxLength={100}
                        placeholder={`Keywords`}

                        selectHintOnEnter={true}
                        emptyLabel={false}
                        onKeyDown={(e)=>{
                          if (e.which === 13 || e.which === 188) {
                            const instance = this.ref_tags;

                            if(instance.getInput().value.trim()) {
                              const {initialItem} = instance.state;
                              instance._handleMenuItemSelect(initialItem, e);
                              e.preventDefault();
                            }
                          }
                        }}
                        ref={(typeahead) => this.ref_tags = typeahead}
                        onChange={(selected) => {
                          const { fields } = this.state;
                          fields.searchbox = selected.map(s => s.label).join(',');
                          this.setState({ fields });
                        }}
                        selected={this.state.fields.searchbox ? this.state.fields.searchbox.split(',').map(k => ({
                          label: k,
                        })) : []}
                        options={[]}
                    />
                  </div>
                  <div className="col position-relative">
                    <div className="">
                      <div className="dropdown">
                        <button className={`btn btn-filter-completed w-100 ${this.state.brokerIndustry.length && 'active'}`} type="button" id="dropdownMenuIndustry" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="me-1">{
                              this.state.brokerIndustry.length
                                  ? this.state.brokerIndustry.length > 3
                                      ? <>Industry <span className="text-xsm">({this.state.brokerIndustry.length})</span></>
                                      : this.state.brokerIndustry.map(industry => industry.label).join(', ')
                                  : 'Industry'
                          }</span>
                          {!this.state.brokerIndustry.length && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}

                        </button>
                        <div className="dropdown-menu dropdown-menu--minw-260" aria-labelledby="dropdownMenuIndustry">
                          <div className="dropdown-content-padding pb-0">
                            <div className="row row-gutter-10">
                              {this.state.brokerIndustry.map(bi => <div className="col-auto mb-2">
                                <button onClick={(e) => e.preventDefault()} className="btn btn-sm btn-primary-blue btn-text-xsm">
                                  <span className="me-2">{bi.label}</span>
                                  <svg onClick={() => this.onRemoveBrokerIndustry(bi)} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                                  </svg>
                                </button>
                              </div>)}
                            </div>
                          </div>
                          {this.state.brokerIndustry.length > 0 && <div className="dropdown-divider"></div>}
                          {this.state.categorylist.map(item => <>
                            <a onClick={() => this.onAddBrokerIndustry(item)} className={`dropdown-item ${item.children.length > 0 ? 'submenu' : ''}`} href="javascript:void(0)">{this.checkedIndustry(item)} {item.label}</a>
                            {item.children.length > 0 && <div className="dropdown-menu">
                              {item.children.map(c =>
                                  <a className="dropdown-item" onClick={() => this.onAddBrokerIndustry(c)} href="javascript:void(0)">{this.checkedIndustry(c)} {c.label}</a>
                              )}
                            </div>}
                          </>)}




                        </div>
                      </div>
                      {this.state.brokerIndustry.length > 0 && <span onClick={() => this.cleanBrokerIndustry()} className="console-right-arrow text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: -25, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                  </div>


                  <div className="col position-relative search-control-border">
                    <div className="dropdown">
                      <button className={`btn btn-filter-completed w-100 text-nowrap ${this.state.fields["minpricerange"] || this.state.fields["maxpricerange"] ? 'active' : ''}`} type="button" id="dropdownMenuPriceRange" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="me-1">{
                          this.state.fields["minpricerange"]
                            ? this.state.fields["maxpricerange"]
                              ? `$${this.state.fields["minpricerange"]} - $${this.state.fields["maxpricerange"]}`
                              : `From $${this.state.fields["minpricerange"]}`
                            : this.state.fields["maxpricerange"]
                              ? `Below $${this.state.fields["maxpricerange"]}`
                              : 'Price Range'
                        } </span>
                        {!(this.state.fields["minpricerange"] || this.state.fields["maxpricerange"]) && <span className="text-xsm console-right-arrow">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                      </button>
                      <div className="dropdown-menu dropdown-menu--minw-310" aria-labelledby="dropdownMenuPriceRange">
                        <div className="py-2 px-3">
                          <div className="row row-gutter-15">
                            <div className="col">
                              <input type="text" className="form-control form-control--bordered mb-2" onChange={this.handleChangeSearch.bind(this, "minpricerange")} value={this.state.fields["minpricerange"]} placeholder="Min" name="minpricerange" />
                              <div className="text-center">
                                <div className="text-center">
                                  <FormGroup check>
                                    <Input type="radio" id="minpricerange1" name="minpricerange"  value="Any Min" onChange={this.handleChangeMinPriceRange.bind()}/>
                                    <Label check htmlFor="minpricerange1">
                                      Any Min
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(50000, true)}>
                                    <Input type="radio" id="minpricerange2" name="minpricerange"  value="50000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(50000, true)}/>
                                    <Label check htmlFor="minpricerange2">
                                      $50,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(100000, true)}>
                                    <Input type="radio" id="minpricerange3" name="minpricerange"  value="100000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(100000, true)}/>
                                    <Label check htmlFor="minpricerange3">
                                      $100,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(250000, true)}>
                                    <Input type="radio" id="minpricerange4" name="minpricerange"  value="250000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(250000, true)}/>
                                    <Label check htmlFor="minpricerange4">
                                      $250,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(500000, true)}>
                                    <Input type="radio" id="minpricerange5" name="minpricerange"  value="500000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(500000, true)}/>
                                    <Label check htmlFor="minpricerange5">
                                      $500,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(750000, true)}>
                                    <Input type="radio" id="minpricerange6" name="minpricerange"  value="750000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(750000, true)}/>
                                    <Label check htmlFor="minpricerange6">
                                      $750,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(1000000, true)}>
                                    <Input type="radio" id="minpricerange7" name="minpricerange"  value="1000000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(1000000, true)}/>
                                    <Label check htmlFor="minpricerange7">
                                      $1,000,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(2500000, true)}>
                                    <Input type="radio" id="minpricerange8" name="minpricerange"  value="2500000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(2500000, true)}/>
                                    <Label check htmlFor="minpricerange8">
                                      $2,500,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(5000000, true)}>
                                    <Input type="radio" id="minpricerange9" name="minpricerange"  value="5000000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(5000000, true)}/>
                                    <Label check htmlFor="minpricerange9">
                                      $5,000,000
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                              <div className="col">
                                <div className={"text-center"}>
                                <input onChange={this.handleChangeSearch.bind(this, "maxpricerange")} value={this.state.fields["maxpricerange"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxpricerange" />
                                <div className="text-center">
                                  <FormGroup check>
                                    <Input type="radio" id="maxpricerange1" name="maxpricerange"  value="Any Max" onChange={this.handleChangeMaxPriceRange.bind()}/>
                                    <Label check htmlFor="maxpricerange1">
                                      Any Max
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(50000, false)}>
                                    <Input type="radio" id="maxpricerange2" name="maxpricerange"  value="50000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(50000, false)}/>
                                    <Label check htmlFor="maxpricerange2">
                                      $50,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(100000, false)}>
                                    <Input type="radio" id="maxpricerange3" name="maxpricerange"  value="100000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(100000, false)}/>
                                    <Label check htmlFor="maxpricerange3">
                                      $100,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(250000, false)}>
                                    <Input type="radio" id="maxpricerange4" name="maxpricerange"  value="250000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(250000, false)}/>
                                    <Label check htmlFor="maxpricerange4">
                                      $250,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(500000, false)}>
                                    <Input type="radio" id="maxpricerange5" name="maxpricerange"  value="500000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(500000, false)}/>
                                    <Label check htmlFor="maxpricerange5">
                                      $500,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(750000, false)}>
                                    <Input type="radio" id="maxpricerange6" name="maxpricerange"  value="750000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(750000, false)}/>
                                    <Label check htmlFor="maxpricerange6">
                                      $750,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(1000000, false)}>
                                    <Input type="radio" id="maxpricerange7" name="maxpricerange"  value="1000000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(1000000, false)}/>
                                    <Label check htmlFor="maxpricerange7">
                                      $1,000,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(2500000, false)}>
                                    <Input type="radio" id="maxpricerange8" name="maxpricerange"  value="2500000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(2500000, false)}/>
                                    <Label check htmlFor="maxpricerange8">
                                      $2,500,000
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check disabled={!isPriceValueAvailable('price')(5000000, false)}>
                                    <Input type="radio" id="maxpricerange9" name="maxpricerange"  value="5000000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(5000000, false)}/>
                                    <Label check htmlFor="maxpricerange9">
                                      $5,000,000
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.fields["minpricerange"] || this.state.fields["maxpricerange"]) && <span onClick={() => this.clearMetrics('minpricerange', 'maxpricerange')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                  </div>


                  <div className="col position-relative">
                    <div className="">
                      <div className="dropdown">
                        <button className={`btn btn-filter-completed w-100 ${this.state.year ? 'active': ''}`} type="button" id="dropdownMenuYear" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="me-1">{this.state.year || 'Year' }</span>
                          {!this.state.year && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                          </span>}

                        </button>
                        <div className="dropdown-menu dropdown-menu--minw-260" aria-labelledby="dropdownMenuYear">
                          {years.map(year =>
                              <a onClick={() => {
                                this.setState({ year })
                              }} className="dropdown-item" href="javascript:void(0)">{year}</a>
                          )}

                        </div>
                      </div>
                      {this.state.year && <span className="text-xsm cursor_pointer" onClick={() => this.setState({ year: null })}>
                            <svg style={{ position: 'absolute', top: 13, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                  </div>
                  <div className="col position-relative">
                    <button onClick={() => this.onFilter()} className="btn btn-primary-blue w-100">Filter</button>
                  </div>
                </Row>
              </div>

              <div className="d-inline-flex" style={{ paddingTop: 50 }}>
                <div className="text-white font-weight-500">{this.state.totalPlaceholder} Transactions</div>
              </div>

              <div className="text-center m-auto justify-content-center container d-flex">
                <Bars
                    color="#3890da"
                    height={50}
                    width={50}
                    ariaLabel="bars-loading"
                    visible={this.state.loading}
                />
              </div>

              <InfiniteScroll
                  dataLength={this.state.list.length}
                  next={this.fetchMoreData}
                  style={{ overflow: 'inherit' }}
                  hasMore={this.state.list.length < this.state.total}
                  loader={this.state.total > 25 ? <div className="text-center justify-content-center container d-flex pt-4">
                    <Bars
                        color="#3890da"
                        height={60}
                        width={60}
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                  </div>: null}
              >
                <div className="row">

                  {this.state.list.map(deal =>
                      <div className="col-md-3 pt-5">
                        <div className="card " style={{ borderRadius: 10, width: 250 }}>
                          <div style={{ position: 'absolute', top: 5, right: 5, fontSize: 10, color: '#2a2f34' }} className="text-md-right btn btn-primary bg-light btn-sm font-weight-normal">
                            {deal.listing_date ? moment(deal.listing_date).format("YYYY") : moment(new Date()).format("YYYY")}
                          </div>
                          <img src={getImageUrl(renderDefaultImage(deal), true)} className="card-img-top"
                               alt="Listing image" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
                          <div className="card-body text-center">
                            <div style={{ height: '200px' }}>
                              {deal.industries.map(c => c.industry.name).map(name =>
                                  <div style={{ fontSize: 10, color: '#2a2f34' }} className="btn btn-primary bg-light btn-sm font-weight-normal me-1 mt-1">
                                    {name}
                                  </div>
                              )}

                              <hr />

                              <div style={{ fontSize: 14 }} className="mb-1 font-weight-500">{sliceText(deal.name, 35)}</div>
                              {(deal.seller_company_name || deal.seller_buyer_type) && <div style={{ fontSize: 12, color: 'gray' }} className="mb-1">Acquired by</div>}
                              <div style={{ fontSize: 14 }} className="font-weight-500">{sliceText(deal.seller_company_name) || sliceText(deal.seller_buyer_type)}</div>

                              <div style={{ position: 'absolute', bottom: 10 }}>
                                <div onClick={() => {
                                  window.location.href = `/profile/${deal.guru_id}`
                                }} className="signup-testimonial" style={{ cursor: 'pointer', width: 208, backgroundColor: '#F1F1F1', border: 'none', padding: 3, borderRadius: 6 }}>
                                  <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div></div>
                                    <div className="ms-2">
                                      <img
                                          src={(deal.guru.profile_pic) ?
                                              (deal.guru.profile_pic.indexOf('base64') > -1) ? deal.guru.profile_pic : (deal.guru.profile_pic.indexOf('user_profile') > -1) ? getImageUrl(deal.guru.profile_pic) : getImageUrl(deal.guru.profile_pic) : process.env.PUBLIC_URL + '/images/thumbnail-small.gif'}
                                          alt="" width="29" height="29"
                                          onError={
                                            (e) => {
                                              //@ts-ignore
                                              if(!e.target.src.includes('images/thumbnail-small.gif')) {
                                                e.target.src = `/images/thumbnail-small.gif`;
                                              }
                                            }
                                          }
                                          className="img-fluid rounded-circle z-depth-0" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="ms-2 text-center">
                                        <div style={{ fontSize: 12 }} className="text-primary-blue">{sliceText(deal.guru.name)}</div>
                                        <div style={{ fontSize: 11 }} className="text-sm">{deal.guru.company && sliceText(deal.guru.company.firm_name)}</div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>
                  )}

                </div>

              </InfiniteScroll>


            </div>


          </div>
          <Footer />
        </>
    );
  }
}



function sliceText(text, count = 20) {
  return text && text.length >= count ? text.slice(0, count) + '...' : text;
}

function renderDefaultImage(data) {
  const images = {
    1293401: process.env.REACT_APP_ROOT + '/images/default_listing/techmedia.jpg',
    1293501: process.env.REACT_APP_ROOT + '/images/default_listing/manufacturing.jpg',
    1293601: process.env.REACT_APP_ROOT + '/images/default_listing/food.jpg',
    1293701: process.env.REACT_APP_ROOT + '/images/default_listing/leisure.jpg',
    1293801: process.env.REACT_APP_ROOT + '/images/default_listing/retail.jpg',
    1293901: process.env.REACT_APP_ROOT + '/images/default_listing/services.jpg',
    1299301: process.env.REACT_APP_ROOT + '/images/default_listing/agriculture.jpg',
    1316601: process.env.REACT_APP_ROOT + '/images/default_listing/engineering.jpg',
    1358401: process.env.REACT_APP_ROOT + '/images/default_listing/franchise.jpg',
    1360201: process.env.REACT_APP_ROOT + '/images/default_listing/realestate.jpg',
    1365401: process.env.REACT_APP_ROOT + '/images/default_listing/wholesale.jpg',
    9999999: process.env.REACT_APP_ROOT + '/images/default_listing/other.jpg', //

    1296101: process.env.REACT_APP_ROOT + '/images/default_listing/maintenance.png',
    1342801: process.env.REACT_APP_ROOT + '/images/default_listing/printing.png',
    1386501: process.env.REACT_APP_ROOT + '/images/default_listing/wedding.png',
    1294502: process.env.REACT_APP_ROOT + '/images/default_listing/advertising.png',
    1297301: process.env.REACT_APP_ROOT + '/images/default_listing/postal.png',
    1297201: process.env.REACT_APP_ROOT + '/images/default_listing/pets.png',
    1296801: process.env.REACT_APP_ROOT + '/images/default_listing/hire.png',
    1296501: process.env.REACT_APP_ROOT + '/images/default_listing/financial.png',
    1296401: process.env.REACT_APP_ROOT + '/images/default_listing/transportation.png',
    1296901: process.env.REACT_APP_ROOT + '/images/default_listing/house.png',
    1299101: process.env.REACT_APP_ROOT + '/images/default_listing/construction.png',
  };
  let image = '';
  const { industries } = data;
  const first_industry = find(industries, e => e.industry.parent_id === 1);
  const first_sub_industry = find(industries, e => e.industry.parent_id !== 1);

  if(first_industry) {
    image = images[first_industry.industry.id]
  } else if(industries?.[0]?.industry?.parent_id) {
    image = images[industries[0].industry.parent_id]
  }

  if(first_sub_industry) {
    image = images[first_sub_industry.industry.id] || process.env.REACT_APP_ROOT + '/images/default_listing/other.jpg'
  }

  return image;
}
