/* eslint-disable */
import React from 'react'
import Geosuggest from '@ubilabs/react-geosuggest';
import {
    Input,
    Form, Modal, ModalHeader, ModalBody, ModalFooter,
    Button, Label, FormGroup, DropdownToggle, DropdownMenu, UncontrolledDropdown
} from 'reactstrap';
import _ from 'lodash';
import queryString from 'query-string'
import {connect} from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import IndustryFilter from "./SearchFilters/IndustryFilter";

class Console extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                searchbox: ''
            },
            errors: {},
            message: '',
            gridview: '12',
            categoryOption: '',
            categorylist: [],
            dateaddedOption: '',
            shortbyOption: '',
            location: '',
            Lat: '',
            Lng: '',
            Searchcollapse: true,
            AdvanceSearchToggle: 'none',
            minpricerangeselected: '',
            maxpricerangeselected: '',
            mincashflowselected: '',
            maxcashflowselected: '',
            minrevenueselected: '',
            maxrevenueselected: '',
            minebitdaselected: '',
            maxebitdaselected: '',
            brokername: '',
            brokerprofile_pic: '',
            brokerslug_url: '',
            brokerfirm_name: '',
            brokerptype: '',
            brokerarea_served: '',
            brokerreviews: '',
            brokerratings: '',
            brokertranscount: '',
            brokerlistcount: '',
            orderby: 'listing_date',
            SearchResultList: [],
            franchiseRecordListing: [],
            totalrecords: '',
            activePage: 1,
            staticNumberOfRow: 25,
            showingData: '',
            showSavedSearch: false,
            savedSearch: { label: '' },
            modal: false,
            savedSearchName: '',
            guideModalOpen: false,
            offMarket: 'yes',

            premiumModal: false,
            dropdownRevenueMultiple: false,
            dropdownCashflowMultiple: false,
            dropdownEBITDAMultiple: false,

            brokerIndustry: [],
            savedSearchList: [],
            savedSearchProjects: [],
            locationType: '',

            city: '',
            state: '',
            country: ''
        };
        this.SubmitSearchResult = this.SubmitSearchResult.bind(this);
        this.clearMetrics = this.clearMetrics.bind(this);
    }

    fetchSavedSearch() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return api.get('user/saved_search', null, true)
        .then(res => {
            this.setState({ 
                savedSearchProjects: res.savedSearchProjects.map(p => {
                    return {
                        label: p.name,
                        value: p.id
                    }
                }),
                savedSearchList: res.savedSearchAll.map(p => {
                    return {
                        label: p.name,
                        value: p,
                        id: p.id
                    }
                }),
                savedListings: res.savedListings.map(l => l.transaction_id)
            });
           
        });
    }

    componentDidMount() {

        fetch(process.env.REACT_APP_API_ROOT + 'industry/legacy', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        }).then(res => res.json())
            .then(
                (res) => {
                    let categorylistarray = [];
                    let temp = {};
                    // temp = { 
                    //     "value": "All",
                    //     "label": "All",
                    //     "children": [],
                    // };
                    // categorylistarray.push(temp);
                    // eslint-disable-next-line
                    res.map((categories, i) => {
                        temp = {
                            "value": categories.value,
                            "label": categories.label,
                            "children": categories.children,
                        };
                        categorylistarray.push(temp);
                    });
                    /*
                    let temp1 = {
                        "value": "Franchise Opportunities",
                        "label": "Franchise Opportunities"
                    }
                    categorylistarray.push(temp1);*/
                    if(localStorage.getItem('token')) {
                        this.fetchSavedSearch()
                        .then(() => {
                            this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                        });
                    } else {
                        this.setState({ categorylist: categorylistarray }, () => { this.parseUrlToSearch();});
                    }
                    

                    
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        //error
                    });
                }
            );
        this.gettopbrockerlist();
        window.onpopstate = () => {
            this.parseUrlToSearch(true);
        }

        this.fetchPremium();


    }

    fetchPremium() {

        if(!localStorage.getItem('token')) {
            return;
        }

        return fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json()).then(res => {
            this.setState({ 
                premium: res.premium,
            });
        });
    }

    componentWillUnmount() {
        window.onpopstate = null;
    }

    parseUrlToSearch = (isFromPopstate = false) => { 
        const queryParams = queryString.parse(window.location.search);
        
        const allIndustries = [];
        this.state.categorylist.forEach(ind => {
            allIndustries.push({...ind, parent_id: 0});
            if(ind.children && ind.children.length) {
                ind.children.forEach(ci => allIndustries.push({...ci, parent_id: ind.value}));
            }
        });
        let categoryOption = queryParams.industry_id
          ? allIndustries.filter(item => {
              const query = queryParams.industry_id.split(',');
              return query.includes(item.value.toString()) 
          })
          : []; 

        categoryOption.filter(co => co.parent_id === 0).forEach(parent => {
            categoryOption = categoryOption.filter(co => co.parent_id !== parent.value)
        }); 
        
        this.setState({
            location: queryParams.location,
            city: queryParams.city,
            state: queryParams.state,
            country: queryParams.country,
            locationType: queryParams.locationType,
            categoryOption,
            brokerIndustry: categoryOption,
            activePage: queryParams.page,
            Lat: queryParams.from_latitude,
            Lng: queryParams.from_longitude,
            dateaddedOption: queryParams.date_added || '',
            offMarket: queryParams.offMarket ? queryParams.offMarket : 'yes',
            offMarketBlock: queryParams.offMarket && queryParams.offMarket === 'yes' ? true : false,
            fields: {
                minpricerange: queryParams.price_min,
                maxpricerange: queryParams.price_max,
                mincashflow: queryParams.cashflow_min,
                maxcashflow: queryParams.cashflow_max,
                minrevenue: queryParams.revenue_min,
                maxrevenue: queryParams.revenue_max,
                minebitda: queryParams.ebitda_min,
                maxebitda: queryParams.ebitda_max,

                minebitda_multiple: queryParams.minebitda_multiple,
                maxebitda_multiple: queryParams.maxebitda_multiple,
                minrevenue_multiple: queryParams.minrevenue_multiple,
                maxrevenue_multiple: queryParams.maxrevenue_multiple,
                mincashflow_multiple: queryParams.mincashflow_multiple,
                maxcashflow_multiple: queryParams.maxcashflow_multiple,

                searchbox: queryParams.name || '',
                radius: queryParams.radius,
                asset_sale: queryParams.asset_sale ? true : false,
            }
        }, () => {
            if(queryParams.search_id) {
                const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.search_id))
                const data = JSON.parse(savedSearch.value.data);
                this.setState({ savedSearch, Lat: data.from_latitude, Lng: data.from_longitude, fields: data.fields, location: data.location, categoryOption: data.categoryOption }, () => {
                    // this.getSearchResultlist({
                    //     orderby: this.state.orderby,
                    //     page: this.state.premium ? this.state.activePage : 1,
                    // }, isFromPopstate);
                });
            } else {
                if(queryParams.selected_search) {
                    const savedSearch = this.state.savedSearchList.find(l => l.id === parseInt(queryParams.selected_search));
                    this.setState({ savedSearch });
                }
                // this.getSearchResultlist({
                //     orderby: this.state.orderby,
                //     page: this.state.premium ? this.state.activePage : 1,
                // }, isFromPopstate);
            }
        });

        
    };


    gettopbrockerlist = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'profile/featured_profile', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    //console.log("Error 401");
                    //console.log(res);
                    //return res.json().then(err => {throw err;});
                    throw res;
                    //throw Error(.statusText);
                } else { return res.json() }

            })
            .then((res) => {
                //console.log(res);
                if (res.length > 0) {
                    let firstname = '';
                    let fullname = res[0].name.match(/(\w+)(?:\s[^\s]+)?\s(\w+).*/);
                    if (fullname !== null) {
                        firstname = fullname[1] + " " + fullname[2].charAt(0) + ".";
                    }
                    else {
                        firstname = res[0].name;
                    }
                    this.setState({ brokername: firstname, brokerprofile_pic: res[0].profile_pic, brokerslug_url: res[0].slug_url, brokerfirm_name: res[0].firm_name, brokerptype: res[0].ptype, brokerarea_served: res[0].area_served, brokerreviews: res[0].reviews, brokerratings: res[0].ratings, brokertranscount: res[0].transcount, brokerlistcount: res[0].listcount, });
                }
            })
            .catch((err) => {
                this.setState({
                    //error
                });
            })
    }
    handleSearchResultValidation() {
        // let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleGeosuggestChange = (location) => {
        if (location !== undefined) {
            let addressComponent = location.gmaps.address_components;
            let locationType = '';
            switch(addressComponent[0].types[0]) {
                case 'locality': locationType = 'city'; break;
                case 'administrative_area_level_1': locationType = 'state'; break;
                case 'country': locationType = 'country'; break;
                default: locationType = 'point';
            }
            for (var j = 0; j < location.gmaps.address_components.length; j++) {
                if (addressComponent[j].types[0] === 'locality') {
                    this.setState({ city: addressComponent[j].long_name });
                }
                if (addressComponent[j].types[0] === 'administrative_area_level_1') {
                    this.setState({ state: addressComponent[j].short_name });
                }
                if (addressComponent[j].types[0] === 'country') {
                    this.setState({ country: addressComponent[j].short_name });
                }
            }
            this.setState({ Lat: location.location.lat });
            this.setState({ Lng: location.location.lng });
            this.setState({ location: location.label, locationType });
            
        }
    }
    handlecategoryChange = (categoryOption) => {
        
        this.setState({ categoryOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (categoryOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handledateaddedChange = (dateaddedOption) => {
        this.setState({ dateaddedOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (dateaddedOption) {
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handleChangeGridview = () => {
        this.setState({ gridview: "3" });
    }
    handleChangeListview = () => {
        this.setState({ gridview: "12" });
    }

    handleChangeSearch(field, e) {
        if (e.target !== '' && e.target !== undefined) {
            let fields = this.state.fields;
            fields[field] = e.target.value;
            
            if (e.target.name === 'minpricerange') {
                let minpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxpricerange') {
                let maxpricerange = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxpricerange.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'mincashflow') {
                let mincashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = mincashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxcashflow') {
                let maxcashflow = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxcashflow.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minrevenue') {
                let minrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxrevenue') {
                let maxrevenue = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxrevenue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'minebitda') {
                let minebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = minebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'maxebitda') {
                let maxebitda = e.target.value.replace(/[^0-9]/g, '');
                fields[field] = maxebitda.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            if (e.target.name === 'asset_sale') {
                fields[field] = e.target.checked;
            }
            if (e.target.name === 'minebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxebitda_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'minrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxrevenue_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'mincashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            if (e.target.name === 'maxcashflow_multiple') {
                fields[field] = e.target.value.replace(/[^0-9.]/g, '');  
            }
            this.setState({ fields });
        }
    }
    handleChangeMinPriceRange = (e) => {
        let fields = this.state.fields;
        fields["minpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minpricerangeselected: e.target.value });
    }
    handleChangeMaxPriceRange = (e) => {
        let fields = this.state.fields;
        fields["maxpricerange"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxpricerangeselected: e.target.value });
    }
    handleChangeMincashflow = (e) => {
        let fields = this.state.fields;
        fields["mincashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, mincashflowselected: e.target.value });
    }
    handleChangeMaxcashflow = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxcashflowselected: e.target.value });
    }
    handleChangeMinRevenue = (e) => {
        let fields = this.state.fields;
        fields["minrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minrevenueselected: e.target.value });
    }
    handleChangeMaxRevenue = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxrevenueselected: e.target.value });
    }
    handleChangeMinEbitda = (e) => {
        let fields = this.state.fields;
        fields["minebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, minebitdaselected: e.target.value });
    }
    //
    handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({ fields, minebitdamultipleselected: e.target.value });
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({ fields, maxebitdamultipleselected: e.target.value });
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({ fields, minrevenuemultipleselected: e.target.value });
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({ fields, maxrevenuemultipleselected: e.target.value });
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({ fields, mincashflowmultipleselected: e.target.value });
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({ fields, maxcashflowmultipleselected: e.target.value });
    }
    //
    
    handleChangeMaxEbitda = (e) => {
        let fields = this.state.fields;
        fields["maxebitda"] = e.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ fields, maxebitdaselected: e.target.value });
    }
    SubmitSearchResult = (e) => {
        e.preventDefault();
        if (this.handleSearchResultValidation()) {
            //let fields = this.state.fields;
            this.getSearchResultlist({
                orderby: this.state.orderby,
                page: 1
            });

        } else {
            //console.log("Form has errors.")
        }
    }
    handleshortbyChange = (shortbyOption) => {
        this.setState({ shortbyOption });
        // selectedOption can be null when the `x` (close) button is clicked
        if (shortbyOption) {
            this.setState({ orderby: shortbyOption.value });
            this.getSearchResultlist({
                orderby: shortbyOption.value,
                page: 1
            });
            // console.log(`Selected: ${shortbyOption.label}`);
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber });
        this.getSearchResultlist({
            orderby: this.state.orderby,
            page: pageNumber,
        });
    }
    handleChangeClear = async (e) => {
        e.preventDefault();
        window.location = '/advanced-search'
        // let fields = this.state.fields;
        // // eslint-disable-next-line
        // fields["searchbox"] = '', fields["minpricerange"] = '', fields["maxpricerange"] = '', fields["mincashflow"] = '', fields["maxcashflow"] = '', fields["minrevenue"] = '', fields["maxrevenue"] = '', fields["radius"] = '';
        // const ClearResult = () => {
        //     this.refs.location.update('');
        //     this.setState({ savedSearch:  null, categoryOption: '', dateaddedOption: '', minpricerangeselected: '', maxpricerangeselected: '', mincashflowselected: '', maxcashflowselected: '', minrevenueselected: '', maxrevenueselected: '', location: '' });
        //     var ip = localStorage.getItem("ip");
        //     // localStorage.clear();
        //     localStorage.setItem('ip', ip);
        // };
        // await ClearResult();
        
        // this.getSearchResultlist({
        //     orderby: this.state.orderby,
        //     page: 1,
        // });
    }
    getSearchResultlist = ({ orderby, page }, isFromPopstate = false) => {
        
        
        this.setState({ activePage: page });
        let location, latitude, longitude, minpricerangeselected, maxpricerangeselected, mincashflowselected, maxcashflowselected, minrevenueselected, maxrevenueselected, dateaddedOption, minebitdaselected, maxebitdaselected = '';
        let fields = this.state.fields;
        if (this.state.location !== '' && this.state.location !== undefined) {
            location = this.state.location;
            latitude = this.state.Lat;
            longitude = this.state.Lng;
        }
        else {
            if (localStorage.getItem("buyloaction") !== null && localStorage.getItem("buyloaction") !== "undefined") {
                location = localStorage.getItem("buyloaction");
                latitude = localStorage.getItem("buylatitude");
                longitude = localStorage.getItem("buylongitude");
            }
            else {
                location = "";
                latitude = ""
                longitude = "";
            }
        }

        if (this.state.dateaddedOption.value !== undefined) {
            dateaddedOption = this.state.dateaddedOption.value;
        }
        else {
            dateaddedOption = this.state.dateaddedOption;
        }
        if (fields["minpricerange"] !== undefined) {
            if (this.state.minpricerangeselected === "Any Min") {
                minpricerangeselected = '';
            }
            else {
                minpricerangeselected = fields["minpricerange"].replace(/,/g, "");
            }
        }
        if (fields["maxpricerange"] !== undefined) {
            if (this.state.maxpricerangeselected === "Any Max") {
                maxpricerangeselected = '';
            }
            else {
                maxpricerangeselected = fields["maxpricerange"].replace(/,/g, "");
            }
        }
        if (fields["mincashflow"] !== undefined) {
            if (this.state.mincashflowselected === "Any Min") {
                mincashflowselected = '';
            }
            else {
                mincashflowselected = fields["mincashflow"].replace(/,/g, "");
            }
        }
        if (fields["maxcashflow"] !== undefined) {
            if (this.state.maxcashflowselected === "Any Max") {
                maxcashflowselected = '';
            }
            else {
                maxcashflowselected = fields["maxcashflow"].replace(/,/g, "");
            }
        }
        if (fields["minrevenue"] !== undefined) {
            if (this.state.minrevenueselected === "Any Min") {
                minrevenueselected = '';
            }
            else {
                minrevenueselected = fields["minrevenue"].replace(/,/g, "");
            }
        }
        if (fields["maxrevenue"] !== undefined) {
            if (this.state.maxrevenueselected === "Any Max") {
                maxrevenueselected = '';
            }
            else {
                maxrevenueselected = fields["maxrevenue"].replace(/,/g, "");
            }
        }
        if (fields["minebitda"] !== undefined) {
            if (this.state.minebitdaselected === "Any Min") {
                minebitdaselected = '';
            }
            else {
                minebitdaselected = fields["minebitda"].replace(/,/g, "");
            }
        }
        if (fields["maxebitda"] !== undefined) {
            if (this.state.maxebitdaselected === "Any Max") {
                maxebitdaselected = '';
            }
            else {
                maxebitdaselected = fields["maxebitda"].replace(/,/g, "");
            }
        }


        const categoryOption = this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children.map(cc => cc.value)) : bi.value);
        
        const dataForQuery = {
          "location": location,
          "industry_id": categoryOption || [],
          "from_latitude": latitude,
          "from_longitude": longitude,
          "name": fields["searchbox"],
          "price_min": minpricerangeselected,
          "price_max": maxpricerangeselected,
          "cashflow_min": mincashflowselected,
          "cashflow_max": maxcashflowselected,
          "revenue_min": minrevenueselected,
          "revenue_max": maxrevenueselected,
          "ebitda_min": minebitdaselected,
          "ebitda_max": maxebitdaselected,
          "date_added": dateaddedOption === "" ? null : dateaddedOption,
          "orderby": orderby,
          "page": page,
          "radius": fields.radius,
          "rows": this.state.staticNumberOfRow,
          "city": this.state.city,
          "state": this.state.state,
          "country": this.state.country,
          "locationType": this.state.locationType, 
          offMarket: this.state.offMarket,
          
        };

        if(this.state.savedSearch.id) {
            dataForQuery.selected_search = this.state.savedSearch.id;
        }

        if(fields['asset_sale']) {
            dataForQuery.asset_sale = '1';
        }

        if(fields['minebitda_multiple'] && fields['minebitda_multiple'] !== 'Any Max' && fields['minebitda_multiple'] !== 'Any Min') {
            dataForQuery.minebitda_multiple = fields['minebitda_multiple'];
        }

        if(fields['maxebitda_multiple'] && fields['maxebitda_multiple'] !== 'Any Max' && fields['maxebitda_multiple'] !== 'Any Min') {
            dataForQuery.maxebitda_multiple = fields['maxebitda_multiple'];
        }

        if(fields['minrevenue_multiple'] && fields['minrevenue_multiple'] !== 'Any Max' && fields['minrevenue_multiple'] !== 'Any Min') {
            dataForQuery.minrevenue_multiple = fields['minrevenue_multiple'];
        }

        if(fields['maxrevenue_multiple'] && fields['maxrevenue_multiple'] !== 'Any Max' && fields['maxrevenue_multiple'] !== 'Any Min') {
            dataForQuery.maxrevenue_multiple = fields['maxrevenue_multiple'];
        }

        if(fields['mincashflow_multiple'] && fields['mincashflow_multiple'] !== 'Any Max' && fields['mincashflow_multiple'] !== 'Any Min') {
            dataForQuery.mincashflow_multiple = fields['mincashflow_multiple'];
        }

        if(fields['maxcashflow_multiple'] && fields['maxcashflow_multiple'] !== 'Any Max' && fields['maxcashflow_multiple'] !== 'Any Min') {
            dataForQuery.maxcashflow_multiple = fields['maxcashflow_multiple'];
        }
        // this.props.getFeaturedTransactions(industry_id ? industry_id : '');
        if(!(this.state.savedSearch && this.state.savedSearch.value)) {
            const recentSearches = localStorage.getItem('recent_searches') ? JSON.parse(localStorage.getItem('recent_searches')) : [];
            recentSearches.unshift(JSON.stringify({
                ...dataForQuery
            }));
            localStorage.setItem('recent_searches', JSON.stringify(recentSearches.slice(0, 3)));
        }
        window.location = '/advanced-search/' + this.makeSearchQueryString(dataForQuery);
    };

    makeSearchQueryString = searchData => {
        delete searchData.rows;
        // in future if this field will be added just remove line below
        delete searchData.orderby;

        let output = '';
        for(let property in searchData){
            if(searchData.hasOwnProperty(property) && searchData[property]){
                if(!output){
                    output = '?'
                } else {
                    output += '&'
                }
                output += property + '=' +  searchData[property];
            }
        }
        return output;
    };


    makeSearch = searchData => {
      fetch(process.env.REACT_APP_API_ROOT + 'transaction/search', {
        method: 'post',
        body: searchData,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(function (res) {
          if (res.status < 200 || res.status >= 300) {
            //console.log("Error 401");
            //console.log(res);
            //return res.json().then(err => {throw err;});
            throw res;
            //throw Error(.statusText);
          } else { return res.json() }
        })
        .then((res) => {
          // document.getElementById('loader').setAttribute("style", "display: none;");
          if (res.status === 200) {
            this.setState({ SearchResultList: res.list, totalrecords: res.total, message: '' })
          } else {
            this.setState({ SearchResultList: [], message: res.message, totalrecords: 0 })
          }
          localStorage.setItem("buyloaction","");
          localStorage.setItem("buylatitude","")
          localStorage.setItem("buylongitude","");
          localStorage.setItem("buycategory","");

        })
        .catch((err) => {
          this.setState({
            //error
          });
        })
    };


    starRatings = (stars) => {
        let ratings = [];
        let filledstars = stars;
        let blankstars = 5 - stars;
        for (let k = 0; k < filledstars; k++) {
            ratings.push(<i className="sprite i-starfill-md" key={"filledstars" + k}></i>);
        }
        for (let k = 0; k < blankstars; k++) {
            ratings.push(<i className="sprite i-star-md" key={"blankstars" + k}></i>);
        }
        return ratings;
    }
    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    handleSaveSearh = () => {
        if(! this.state.savedSearchName.trim()) {
            return;
        }

        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'post',
            body: JSON.stringify({ name: this.state.savedSearchName, data: JSON.stringify({
                fields: this.state.fields,
                location: this.state.location,
                categoryOption: this.state.categoryOption,
                from_latitude: this.state.Lat,
                from_longitude: this.state.Lng,
            }) }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({ showSavedSearch: false });
            this.fetchSavedSearch();
        })
    }

    handleDeleteSavedSearch = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'delete',
            body: JSON.stringify({ id:  this.state.savedSearch.value.id}),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              "Access-Control-Allow-Origin": "*",
              'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({ showSavedSearch: false, savedSearch: null });
            this.fetchSavedSearch();
        });
    }

    isToUpgrade() {
        return !this.state.premium && localStorage.getItem('token');
    }

     //
     handleChangeMinEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["minebitda_multiple"] = e.target.value;
        this.setState({ fields, minebitdamultipleselected: e.target.value });
    }
    handleChangeMaxEbitdaMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxebitda_multiple"] = e.target.value;
        this.setState({ fields, maxebitdamultipleselected: e.target.value });
    }

    handleChangeMinRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["minrevenue_multiple"] = e.target.value;
        this.setState({ fields, minrevenuemultipleselected: e.target.value });
    }
    handleChangeMaxRevenueMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxrevenue_multiple"] = e.target.value;
        this.setState({ fields, maxrevenuemultipleselected: e.target.value });
    }

    handleChangeMinCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["mincashflow_multiple"] = e.target.value;
        this.setState({ fields, mincashflowmultipleselected: e.target.value });
    }
    handleChangeMaxCashflowMultiple = (e) => {
        let fields = this.state.fields;
        fields["maxcashflow_multiple"] = e.target.value;
        this.setState({ fields, maxcashflowmultipleselected: e.target.value });
    }

    onAddBrokerIndustry = item => {
        const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);
        
        if( ! exist) {
            this.setState({
                brokerIndustry: this.state.brokerIndustry.concat(item)
            }, () => {
                this.handleCategoryChange();
            });
        }
    }

    checkedIndustry = item => {
        const exist = _.find(this.state.brokerIndustry, b => b.value === item.value);

        if(exist) {
            return (
                <i className="fa-solid fa-check"></i>
            )
        }
    }

    onRemoveBrokerIndustry = item => {
        const brokerIndustry = _.reject(this.state.brokerIndustry, bi => bi.value === item.value);
        this.setState({
            brokerIndustry
        }, () => {
            this.handleCategoryChange();
        });
    }

    onUpdateBrokerIndustry = (items) => {
        this.setState({
            brokerIndustry: items
        }, () => {
            this.handleCategoryChange();
        });
    }

    handleCategoryChange = () => {
        // this.state.brokerIndustry.map(bi => bi.children && bi.children.length ? [bi.value].concat(bi.children) : []
    }

    cleanBrokerIndustry = () => {
        this.setState({ brokerIndustry: [] });
    }

    clearMetrics = (a, b) => {
        let fields = this.state.fields;
        fields[a] = '';
        fields[b] = '';
        this.setState({ fields });
    }

    render() {
        const priceValues = {
            price: {
                min: +this.state.fields.minpricerange?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxpricerange?.replace(/[^0-9]/g, ''),
            },
            revenue: {
                min: +this.state.fields.minrevenue?.replace(/[^0-9]/g, ''),
                max: +this.state.fields.maxrevenue?.replace(/[^0-9]/g, ''),
            },
            revenue_multiple: {
                min: +this.state.fields.minrevenue_multiple,
                max: +this.state.fields.maxrevenue_multiple,
            },
            ebitda_multiple: {
                min: +this.state.fields.minebitda_multiple,
                max: +this.state.fields.maxebitda_multiple,
            },
            cashflow_multiple: {
                min: +this.state.fields.mincashflow_multiple,
                max: +this.state.fields.maxcashflow_multiple,
            },
        }
        const isPriceValueAvailable = (priceType) => (value, isMin) => {
            if (isMin) {
                return !priceValues[priceType].max || priceValues[priceType].max > value
            }
            return !priceValues[priceType].min || priceValues[priceType].min < value
        }
        Object.keys(priceValues).forEach(priceType => {
            priceValues[priceType].isValid = !priceValues[priceType].min ||
                !priceValues[priceType].max ||
                priceValues[priceType].min < priceValues[priceType].max
        })
        const isButtonDisabled = Object.keys(priceValues).reduce((prev, priceType) => prev || !priceValues[priceType].isValid, false)
        return (
            <Form onSubmit={this.SubmitSearchResult}><section className="content-section-padding homepage sidebar-card-design search_console_mobile">
            <div className="position-relative mb-3">
            <label for="keywords" className="sr-only">Search</label>
            {/* <input 
                type="text"
                value={this.state.fields.searchbox ? this.state.fields.searchbox.split(',').join(' '): ''} 
                autoComplete="off"
                onChange={(e) => {
                    const { fields } = this.state;
                    fields.searchbox = e.target.value.split(' ').join(',')
                    this.setState({ fields });
                }}
                placeholder="Try 'SaaS' or 'HVAC' etc." className="form-control pr-5" id="keywords" aria-describedby="emailHelp"
            /> */}
            <Typeahead
                multiple
                allowNew
                id="searchbox"
                className={'search_input_mobile'}
                newSelectionPrefix="Add a keyword"
                name="searchbox"
                maxLength={100}
                placeholder={`Try: SaaS or "recurring revenue"`}
                onBlur={(e) => {
                    const instance = this.ref_tags;

                    if(instance.getInput().value.trim()) {
                        const {initialItem} = instance.state;
                        instance._handleMenuItemSelect(initialItem, e);
                    }
                }}
                selectHintOnEnter={true}
                emptyLabel={false}
                onKeyDown={(e)=>{
                    if (e.which === 13 || e.which === 188) {
                        const instance = this.ref_tags;

                        if(instance.getInput().value.trim()) {
                            const {initialItem} = instance.state;
                            instance._handleMenuItemSelect(initialItem, e);
                            e.preventDefault();
                        }
                    }
                }}
                ref={(typeahead) => this.ref_tags = typeahead}
                onChange={(selected) => {
                    const { fields } = this.state;
                    fields.searchbox = selected.map(s => s.label).join(',');
                    this.setState({ fields });
                }}
                selected={this.state.fields.searchbox ? this.state.fields.searchbox.split(',').map(k => ({
                    label: k,
                })) : []}
                options={[]}
            />
            <button type="submit" className="btn btn-primary btn-input-right" disabled={isButtonDisabled} style={{ width: 120, height: 40 }}>
                Search Now
            </button>
            </div>
            <div className="d-md-block">
                <div className="row align-items-center row-gutter-10" style={{position: 'relative'}}>
                    {/* <!-- Industry Filter --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                        <div className="dropdown">
                            <button className={`btn btn-filter w-100 ${this.state.brokerIndustry.length && 'active'}`} type="button" onClick={() => this.setState({ showIndustryFilter: !this.state.showIndustryFilter })}>
                            <span className="me-1">Industry <span className="text-xsm">({this.state.brokerIndustry.length})</span></span>
                            {!this.state.brokerIndustry.length && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                            
                            </button>
                        </div>
                        {this.state.brokerIndustry.length > 0 && <span onClick={() => this.cleanBrokerIndustry()} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Search by location --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                        <label for="by-location" className="sr-only">Search by location</label>
                        <Geosuggest
                            inputClassName={`text-center form-control form-control--filter ${this.state.location ? 'btn-filter active' : ''}`}
                            id="by-location"
                            name="location"
                            ref="location"
                            placeholder="Search by location"
                            onSuggestSelect={this.handleGeosuggestChange}
                            initialValue={this.state.location}
                        />
                        {this.state.location && <span onClick={() => this.setState({ location: '' })} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Search by radius --> */}
                    {/* <div className="col-6 col-md-4 col-lg-3 mb-2">
                        <label for="by-radius" className="sr-only">Search by radius</label>
                        <input type="number" onChange={(e) => {
                            const { fields } = this.state;
                            fields.radius = e.target.value;
                            this.setState({ fields });
                        }} value={this.state.fields.radius} placeholder="Radius" id="by-radius" name="by-radius" className="form-control form-control--filter" />
                    </div> */}
                    {/* <!-- Saved search --> */}
                    
                    {/* <!-- Price range --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button className={`btn btn-filter w-100 text-nowrap ${this.state.fields["minpricerange"] || this.state.fields["maxpricerange"] ? 'active' : ''}`} type="button" >
                                    <span className="me-1">Price Range</span>
                                    {!(this.state.fields["minpricerange"] || this.state.fields["maxpricerange"]) && <span className="text-xsm console-right-arrow">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                <div className="py-2 px-3">
                                    <div className="row row-gutter-15">
                                        <div className="col">
                                            <input type="text" className="form-control form-control--bordered mb-2" onChange={this.handleChangeSearch.bind(this, "minpricerange")} value={this.state.fields["minpricerange"]} placeholder="Min" name="minpricerange" />
                                            <div className="text-center">
                                                <FormGroup check>
                                                    <Input type="radio" id="minpricerange1" name="minpricerange"  value="Any Min" onChange={this.handleChangeMinPriceRange.bind()}/>
                                                    <Label check htmlFor="minpricerange1">
                                                        Any Min
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(50000, true)}>
                                                    <Input type="radio" id="minpricerange2" name="minpricerange"  value="50000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(50000, true)}/>
                                                    <Label check htmlFor="minpricerange2">
                                                        $50,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(100000, true)}>
                                                    <Input type="radio" id="minpricerange3" name="minpricerange"  value="100000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(100000, true)}/>
                                                    <Label check htmlFor="minpricerange3">
                                                        $100,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(250000, true)}>
                                                    <Input type="radio" id="minpricerange4" name="minpricerange"  value="250000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(250000, true)}/>
                                                    <Label check htmlFor="minpricerange4">
                                                        $250,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(500000, true)}>
                                                    <Input type="radio" id="minpricerange5" name="minpricerange"  value="500000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(500000, true)}/>
                                                    <Label check htmlFor="minpricerange5">
                                                        $500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(750000, true)}>
                                                    <Input type="radio" id="minpricerange6" name="minpricerange"  value="750000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(750000, true)}/>
                                                    <Label check htmlFor="minpricerange6">
                                                        $750,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(1000000, true)}>
                                                    <Input type="radio" id="minpricerange7" name="minpricerange"  value="1000000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(1000000, true)}/>
                                                    <Label check htmlFor="minpricerange7">
                                                        $1,000,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(2500000, true)}>
                                                    <Input type="radio" id="minpricerange8" name="minpricerange"  value="2500000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(2500000, true)}/>
                                                    <Label check htmlFor="minpricerange8">
                                                        $2,500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(5000000, true)}>
                                                    <Input type="radio" id="minpricerange9" name="minpricerange"  value="5000000" onChange={this.handleChangeMinPriceRange.bind()} disabled={!isPriceValueAvailable('price')(5000000, true)}/>
                                                    <Label check htmlFor="minpricerange9">
                                                        $5,000,000
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <input onChange={this.handleChangeSearch.bind(this, "maxpricerange")} value={this.state.fields["maxpricerange"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxpricerange" />
                                            <div className="text-center">
                                                <FormGroup check>
                                                    <Input type="radio" id="maxpricerange1" name="maxpricerange"  value="Any Max" onChange={this.handleChangeMaxPriceRange.bind()}/>
                                                    <Label check htmlFor="maxpricerange1">
                                                        Any Max
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(50000, false)}>
                                                    <Input type="radio" id="maxpricerange2" name="maxpricerange"  value="50000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(50000, false)}/>
                                                    <Label check htmlFor="maxpricerange2">
                                                        $50,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(100000, false)}>
                                                    <Input type="radio" id="maxpricerange3" name="maxpricerange"  value="100000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(100000, false)}/>
                                                    <Label check htmlFor="maxpricerange3">
                                                        $100,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(250000, false)}>
                                                    <Input type="radio" id="maxpricerange4" name="maxpricerange"  value="250000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(250000, false)}/>
                                                    <Label check htmlFor="maxpricerange4">
                                                        $250,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(500000, false)}>
                                                    <Input type="radio" id="maxpricerange5" name="maxpricerange"  value="500000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(500000, false)}/>
                                                    <Label check htmlFor="maxpricerange5">
                                                        $500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(750000, false)}>
                                                    <Input type="radio" id="maxpricerange6" name="maxpricerange"  value="750000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(750000, false)}/>
                                                    <Label check htmlFor="maxpricerange6">
                                                        $750,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(1000000, false)}>
                                                    <Input type="radio" id="maxpricerange7" name="maxpricerange"  value="1000000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(1000000, false)}/>
                                                    <Label check htmlFor="maxpricerange7">
                                                        $1,000,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(2500000, false)}>
                                                    <Input type="radio" id="maxpricerange8" name="maxpricerange"  value="2500000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(2500000, false)}/>
                                                    <Label check htmlFor="maxpricerange8">
                                                        $2,500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('price')(5000000, false)}>
                                                    <Input type="radio" id="maxpricerange9" name="maxpricerange"  value="5000000" onChange={this.handleChangeMaxPriceRange.bind()} disabled={!isPriceValueAvailable('price')(5000000, false)}/>
                                                    <Label check htmlFor="maxpricerange9">
                                                        $5,000,000
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {(this.state.fields["minpricerange"] || this.state.fields["maxpricerange"]) && <span onClick={() => this.clearMetrics('minpricerange', 'maxpricerange')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>

                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button className={`btn btn-filter w-100 text-nowrap ${this.state.savedSearch.label ? 'active': ''}`} type="button">
                                    <span className="text-overflow-ellipsis me-1">{this.state.savedSearch.label || 'Saved Search'}</span>
                                    {!this.state.savedSearch.label && <span className="console-right-arrow text-xsm">
                                    <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                    </svg>
                                </span>}
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu">
                                {this.state.savedSearchList.map(ss =>
                                    <a onClick={() => {
                                        const data = JSON.parse(ss.value.data);
                                        this.setState({ savedSearch: ss, Lat: data.from_latitude, Lng: data.from_longitude, fields: data.fields, location: data.location, categoryOption: data.categoryOption,
                                            city: data.city,
                                            state: data.state,
                                            country: data.country,
                                            locationType: data.locationType,
                                        });
                                    }} className="dropdown-item" href="javascript:void(0)">{ss.label}</a>
                                )}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {this.state.savedSearch.label && <span className="text-xsm cursor_pointer" onClick={() => this.setState({ savedSearch: {} })}>
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                        
                    </div>
                    {/* <!-- Annual revenue --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button className={`btn btn-filter w-100 text-nowrap ${this.state.fields["minrevenue"] || this.state.fields["maxrevenue"] ? 'active' : ''}`} type="button">
                                    <span className="me-1">Annual revenue</span>
                                    {!(this.state.fields["minrevenue"] || this.state.fields["maxrevenue"]) && <span className="console-right-arrow text-xsm">
                                        <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                        </svg>
                                    </span>}
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                <div className="py-2 px-3">
                                    <div className="row row-gutter-15">
                                        <div className="col">
                                            <input type="text" className="form-control form-control--bordered mb-2" onChange={this.handleChangeSearch.bind(this, "minrevenue")} value={this.state.fields["minrevenue"]} placeholder="Min" name="minrevenue" />
                                            <div className="text-center">
                                                <FormGroup check>
                                                    <Input type="radio" id="minrevenue1" name="minrevenue"  value="Any Min" onChange={this.handleChangeMinRevenue.bind()}/>
                                                    <Label check htmlFor="minrevenue1">
                                                        Any Min
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(50000, true)}>
                                                    <Input type="radio" id="minrevenue2" name="minrevenue"  value="50000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(50000, true)}/>
                                                    <Label check htmlFor="minrevenue2">
                                                        $50,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(100000, true)}>
                                                    <Input type="radio" id="minrevenue3" name="minrevenue"  value="100000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(100000, true)}/>
                                                    <Label check htmlFor="minrevenue3">
                                                        $100,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(250000, true)}>
                                                    <Input type="radio" id="minrevenue4" name="minrevenue"  value="250000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(250000, true)}/>
                                                    <Label check htmlFor="minrevenue4">
                                                        $250,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(500000, true)}>
                                                    <Input type="radio" id="minrevenue5" name="minrevenue"  value="500000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(500000, true)}/>
                                                    <Label check htmlFor="minrevenue5">
                                                        $500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(750000, true)}>
                                                    <Input type="radio" id="minrevenue6" name="minrevenue"  value="750000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(750000, true)}/>
                                                    <Label check htmlFor="minrevenue6">
                                                        $750,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(1000000, true)}>
                                                    <Input type="radio" id="minrevenue7" name="minrevenue"  value="1000000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(1000000, true)}/>
                                                    <Label check htmlFor="minrevenue7">
                                                        $1,000,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(2500000, true)}>
                                                    <Input type="radio" id="minrevenue8" name="minrevenue"  value="2500000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(2500000, true)}/>
                                                    <Label check htmlFor="minrevenue8">
                                                        $2,500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(5000000, true)}>
                                                    <Input type="radio" id="minrevenue9" name="minrevenue"  value="5000000" onChange={this.handleChangeMinRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(5000000, true)}/>
                                                    <Label check htmlFor="minrevenue9">
                                                        $5,000,000
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <input onChange={this.handleChangeSearch.bind(this, "maxrevenue")} value={this.state.fields["maxrevenue"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxrevenue" />
                                            <div className="text-center">
                                                <FormGroup check>
                                                    <Input type="radio" id="maxrevenue1" name="maxrevenue"  value="Any Max" onChange={this.handleChangeMaxRevenue.bind()}/>
                                                    <Label check htmlFor="maxrevenue1">
                                                        Any Max
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(50000, false)}>
                                                    <Input type="radio" id="maxrevenue2" name="maxrevenue"  value="50000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(50000, false)}/>
                                                    <Label check htmlFor="maxrevenue2">
                                                        $50,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(100000, false)}>
                                                    <Input type="radio" id="maxrevenue3" name="maxrevenue"  value="100000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(100000, false)}/>
                                                    <Label check htmlFor="maxrevenue3">
                                                        $100,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(250000, false)}>
                                                    <Input type="radio" id="maxrevenue4" name="maxrevenue"  value="250000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(250000, false)}/>
                                                    <Label check htmlFor="maxrevenue4">
                                                        $250,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(500000, false)}>
                                                    <Input type="radio" id="maxrevenue5" name="maxrevenue"  value="500000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(500000, false)}/>
                                                    <Label check htmlFor="maxrevenue5">
                                                        $500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(750000, false)}>
                                                    <Input type="radio" id="maxrevenue6" name="maxrevenue"  value="750000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(750000, false)}/>
                                                    <Label check htmlFor="maxrevenue6">
                                                        $750,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(1000000, false)}>
                                                    <Input type="radio" id="maxrevenue7" name="maxrevenue"  value="1000000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(1000000, false)}/>
                                                    <Label check htmlFor="maxrevenue7">
                                                        $1,000,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(2500000, false)}>
                                                    <Input type="radio" id="maxrevenue8" name="maxrevenue"  value="2500000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(2500000, false)}/>
                                                    <Label check htmlFor="maxrevenue8">
                                                        $2,500,000
                                                    </Label>
                                                </FormGroup>

                                                <FormGroup check disabled={!isPriceValueAvailable('revenue')(5000000, false)}>
                                                    <Input type="radio" id="maxrevenue9" name="maxrevenue"  value="5000000" onChange={this.handleChangeMaxRevenue.bind()} disabled={!isPriceValueAvailable('revenue')(5000000, false)}/>
                                                    <Label check htmlFor="maxrevenue9">
                                                        $5,000,000
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {(this.state.fields["minrevenue"] || this.state.fields["maxrevenue"]) && <span onClick={() => this.clearMetrics('minrevenue', 'maxrevenue')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Annual profit --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button className={`btn btn-filter w-100 text-nowrap ${this.state.fields["mincashflow"] || this.state.fields["maxcashflow"] || this.state.fields["minebitda"] || this.state.fields["maxebitda"] ? 'active' : ''}`} type="button">
                                    <span className="text-overflow-ellipsis me-1">Annual Profit</span>
                                    {!(this.state.fields["mincashflow"] || this.state.fields["maxcashflow"]) && <span className="console-right-arrow text-xsm">
                                    <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                    </svg>
                                </span>}
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                <div className="py-2 px-3">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-cashflow" role="tabpanel" aria-labelledby="nav-cashflow-tab">
                                            <div className="row row-gutter-15">
                                                <div className="col">
                                                    <input onChange={this.handleChangeSearch.bind(this, "mincashflow")} value={this.state.fields["mincashflow"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Min" name="mincashflow" />
                                                    <div className="text-center">
                                                        <FormGroup check>
                                                            <Input type="radio" id="mincashflow1" name="mincashflow"  value="Any Min" onChange={this.handleChangeMincashflow.bind()}/>
                                                            <Label check htmlFor="mincashflow1">
                                                                Any Min
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 50000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow2" name="mincashflow"  value="50000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 50000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow2">
                                                                $50,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 100000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow3" name="mincashflow"  value="100000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 100000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow3">
                                                                $100,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 250000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow4" name="mincashflow"  value="250000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 250000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow4">
                                                                $250,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 500000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow5" name="mincashflow"  value="500000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 500000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow5">
                                                                $500,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 750000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow6" name="mincashflow"  value="750000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 750000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow6">
                                                                $750,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 1000000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow7" name="mincashflow"  value="1000000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 1000000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow7">
                                                                $1,000,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 2500000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow8" name="mincashflow"  value="2500000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 2500000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow8">
                                                                $2,500,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.maxcashflowselected && 5000000 > this.state.maxcashflowselected}>
                                                            <Input type="radio" id="mincashflow9" name="mincashflow"  value="5000000" onChange={this.handleChangeMincashflow.bind()} disabled={this.state.maxcashflowselected && 5000000 > this.state.maxcashflowselected}/>
                                                            <Label check htmlFor="mincashflow9">
                                                                $5,000,000
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <input  onChange={this.handleChangeSearch.bind(this, "maxcashflow")} value={this.state.fields["maxcashflow"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxcashflow" />
                                                    <div className="text-center">

                                                        <FormGroup check>
                                                            <Input type="radio" id="maxcashflow1" name="maxcashflow"  value="Any Max" onChange={this.handleChangeMaxcashflow.bind()}/>
                                                            <Label check htmlFor="maxcashflow1">
                                                                Any Max
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 50000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow2" name="maxcashflow"  value="50000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 50000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow2">
                                                                $50,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 100000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow3" name="maxcashflow"  value="100000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 100000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow3">
                                                                $100,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 250000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow4" name="maxcashflow"  value="250000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 250000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow4">
                                                                $250,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 500000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow5" name="maxcashflow"  value="500000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 500000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow5">
                                                                $500,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 750000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow6" name="maxcashflow"  value="750000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 750000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow6">
                                                                $750,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 1000000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow7" name="maxcashflow"  value="1000000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 1000000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow7">
                                                                $1,000,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 2500000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow8" name="maxcashflow"  value="2500000" onChange={this.handleChangeMaxcashflow.bind()} disabled={this.state.mincashflowselected && 2500000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow8">
                                                                $2,500,000
                                                            </Label>
                                                        </FormGroup>

                                                        <FormGroup check disabled={this.state.mincashflowselected && 5000000 <= this.state.mincashflowselected}>
                                                            <Input type="radio" id="maxcashflow9" name="maxcashflow"  value="5000000" onChange={this.handleChangeMaxcashflow.bind()}  disabled={this.state.mincashflowselected && 5000000 <= this.state.mincashflowselected}/>
                                                            <Label check htmlFor="maxcashflow9">
                                                                $5,000,000
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {(this.state.fields["mincashflow"] || this.state.fields["maxcashflow"] || this.state.fields["minebitda"] || this.state.fields["maxebitda"]) && <span onClick={() => {
                            this.clearMetrics('mincashflow', 'maxcashflow')
                            this.clearMetrics('minebitda', 'maxebitda')
                        }} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Date added --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button className={`btn btn-filter w-100 ${this.state.dateaddedOption.label ? 'active' : ''}`} type="button">
                                    <span className="me-1">{this.state.dateaddedOption.label || 'Date Updated'}</span>
                                    {!this.state.dateaddedOption.label && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                                </button>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu--minw-260">
                                {[{ value: `all`, label: `All` },
                                    { value: `lastweek`, label: `Within the last week` },
                                    { value: `lastmonth`, label: `Within the last month` },
                                ].map(da => <a onClick={() => this.handledateaddedChange(da)} className="dropdown-item" href="javascript:void(0)">{da.label}</a>)}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {this.state.dateaddedOption.label && <span onClick={() => this.setState({ dateaddedOption: {} })} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Revenue multiple --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button onClick={(e) => {
                                    if(!this.state.premium) {
                                        this.setState({ premiumModal: true });
                                    }
                                }} className={`btn btn-filter w-100 text-nowrap ${this.state.fields["minrevenue_multiple"] || this.state.fields["maxrevenue_multiple"] ? 'active' : ''}`} type="button">
                                    <span className="me-1">Revenue multiple</span>
                                    {!(this.state.fields["minrevenue_multiple"] || this.state.fields["maxrevenue_multiple"]) && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                                </button>
                            </DropdownToggle>
                            {this.state.premium &&
                                <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                    <div className="py-2 px-3">
                                        <div className="row row-gutter-15">
                                            <div className="col">
                                                <input type="text" className="form-control form-control--bordered mb-2"
                                                       onChange={this.handleChangeSearch.bind(this, "minrevenue_multiple")}
                                                       value={this.state.fields["minrevenue_multiple"]}
                                                       placeholder="Min" name="minrevenue_multiple"/>
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="minrevenue_multiple1"
                                                               name="minrevenue_multiple" value="Any Min"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}/>
                                                        <Label check htmlFor="minrevenue_multiple1">
                                                            Any Min
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(0.5, true)}>
                                                        <Input type="radio" id="minrevenue_multiple2"
                                                               name="minrevenue_multiple" value="0.5"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(0.5, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1, true)}>
                                                        <Input type="radio" id="minrevenue_multiple3"
                                                               name="minrevenue_multiple" value="1"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1.5, true)}>
                                                        <Input type="radio" id="minrevenue_multiple4"
                                                               name="minrevenue_multiple" value="1.5"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1.5, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2, true)}>
                                                        <Input type="radio" id="minrevenue_multiple5"
                                                               name="minrevenue_multiple" value="2"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2.5, true)}>
                                                        <Input type="radio" id="minrevenue_multiple6"
                                                               name="minrevenue_multiple" value="2.5"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2.5, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3, true)}>
                                                        <Input type="radio" id="minrevenue_multiple7"
                                                               name="minrevenue_multiple" value="3"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3.5, true)}>
                                                        <Input type="radio" id="minrevenue_multiple8"
                                                               name="minrevenue_multiple" value="3.5"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3.5, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(4, true)}>
                                                        <Input type="radio" id="minrevenue_multiple9"
                                                               name="minrevenue_multiple" value="4"
                                                               onChange={this.handleChangeMinRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(4, true)}/>
                                                        <Label check htmlFor="minrevenue_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input
                                                    onChange={this.handleChangeSearch.bind(this, "maxrevenue_multiple")}
                                                    value={this.state.fields["maxrevenue_multiple"]} type="text"
                                                    className="form-control form-control--bordered mb-2"
                                                    placeholder="Max" name="maxrevenue_multiple"/>
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="maxrevenue_multiple1"
                                                               name="maxrevenue_multiple" value="Any Max"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}/>
                                                        <Label check htmlFor="maxrevenue_multiple1">
                                                            Any Max
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(0.5, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple2"
                                                               name="maxrevenue_multiple" value="0.5"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(0.5, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple3"
                                                               name="maxrevenue_multiple" value="1"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1.5, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple4"
                                                               name="maxrevenue_multiple" value="1.5"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(1.5, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple5"
                                                               name="maxrevenue_multiple" value="2"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2.5, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple6"
                                                               name="maxrevenue_multiple" value="2.5"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(2.5, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple7"
                                                               name="maxrevenue_multiple" value="3"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3.5, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple8"
                                                               name="maxrevenue_multiple" value="3.5"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(3.5, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(4, false)}>
                                                        <Input type="radio" id="maxrevenue_multiple9"
                                                               name="maxrevenue_multiple" value="4"
                                                               onChange={this.handleChangeMaxRevenueMultiple.bind()}
                                                               disabled={!isPriceValueAvailable('revenue_multiple')(4, false)}/>
                                                        <Label check htmlFor="maxrevenue_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            }
                        </UncontrolledDropdown>
                        {(this.state.fields["minrevenue_multiple"] || this.state.fields["maxrevenue_multiple"]) && <span onClick={() => this.clearMetrics('minrevenue_multiple', 'maxrevenue_multiple')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- SDE Multiple --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button onClick={(e) => {
                                    if(!this.state.premium) {
                                        this.setState({ premiumModal: true });
                                    }
                                }} className={`btn btn-filter w-100 text-nowrap ${this.state.fields["mincashflow_multiple"] || this.state.fields["maxcashflow_multiple"] ? 'active' : ''}`} type="button">
                                    <span className="me-1">SDE Multiple</span>
                                    {!(this.state.fields["mincashflow_multiple"] || this.state.fields["maxcashflow_multiple"]) && <span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                                </button>
                            </DropdownToggle>
                            {this.state.premium &&
                                <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                    <div className="py-2 px-3">
                                        <div className="row row-gutter-15">
                                            <div className="col">
                                                <input type="text" className="form-control form-control--bordered mb-2" onChange={this.handleChangeSearch.bind(this, "mincashflow_multiple")} value={this.state.fields["mincashflow_multiple"]} placeholder="Min" name="mincashflow_multiple" />
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="mincashflow_multiple1" name="mincashflow_multiple"  value="Any Min" onChange={this.handleChangeMinCashflowMultiple.bind()}/>
                                                        <Label check htmlFor="mincashflow_multiple1">
                                                            Any Min
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(0.5, true)}>
                                                        <Input type="radio" id="mincashflow_multiple2" name="mincashflow_multiple"  value="0.5" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(0.5, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(1, true)}>
                                                        <Input type="radio" id="mincashflow_multiple3" name="mincashflow_multiple"  value="1" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(1, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(1.5, true)}>
                                                        <Input type="radio" id="mincashflow_multiple4" name="mincashflow_multiple"  value="1.5" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(1.5, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(2, true)}>
                                                        <Input type="radio" id="mincashflow_multiple5" name="mincashflow_multiple"  value="2" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(2, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(2.5, true)}>
                                                        <Input type="radio" id="mincashflow_multiple6" name="mincashflow_multiple"  value="2.5" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(2.5, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(3, true)}>
                                                        <Input type="radio" id="mincashflow_multiple7" name="mincashflow_multiple"  value="3" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(3, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(3.5, true)}>
                                                        <Input type="radio" id="mincashflow_multiple8" name="mincashflow_multiple"  value="3.5" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(3.5, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(4, true)}>
                                                        <Input type="radio" id="mincashflow_multiple9" name="mincashflow_multiple"  value="4" onChange={this.handleChangeMinCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(4, true)}/>
                                                        <Label check htmlFor="mincashflow_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input onChange={this.handleChangeSearch.bind(this, "maxcashflow_multiple")} value={this.state.fields["maxcashflow_multiple"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxcashflow_multiple" />
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="maxcashflow_multiple1" name="maxcashflow_multiple"  value="Any Max" onChange={this.handleChangeMaxCashflowMultiple.bind()}/>
                                                        <Label check htmlFor="maxcashflow_multiple1">
                                                            Any Max
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(0.5, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple2" name="maxcashflow_multiple"  value="0.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(0.5, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(1, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple3" name="maxcashflow_multiple"  value="1" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(1, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(1.5, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple4" name="maxcashflow_multiple"  value="1.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(1.5, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(2, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple5" name="maxcashflow_multiple"  value="2" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(2, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(2.5, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple6" name="maxcashflow_multiple"  value="2.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(2.5, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(3, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple7" name="maxcashflow_multiple"  value="3" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(3, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(3.5, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple8" name="maxcashflow_multiple"  value="3.5" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(3.5, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('cashflow_multiple')(4, false)}>
                                                        <Input type="radio" id="maxcashflow_multiple9" name="maxcashflow_multiple"  value="4" onChange={this.handleChangeMaxCashflowMultiple.bind()} disabled={!isPriceValueAvailable('cashflow_multiple')(4, false)}/>
                                                        <Label check htmlFor="maxcashflow_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            }
                        </UncontrolledDropdown>
                        {(this.state.fields["mincashflow_multiple"] || this.state.fields["maxcashflow_multiple"]) && <span onClick={() => this.clearMetrics('mincashflow_multiple', 'maxcashflow_multiple')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- EBITDA Multiple --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2 search-control-border">
                        <UncontrolledDropdown >
                            <DropdownToggle className="dropdown-number-toggle">
                                <button onClick={(e) => {
                                    if(!this.state.premium) {
                                        this.setState({ premiumModal: true });
                                    }
                                }} className={`btn btn-filter w-100 text-nowrap ${this.state.fields["minebitda_multiple"] || this.state.fields["maxebitda_multiple"] ? 'active' : ''}`} type="button">
                                    <span className="me-1">EBITDA Multiple</span>
                                    {!(this.state.fields["minebitda_multiple"] || this.state.fields["maxebitda_multiple"]) &&<span className="console-right-arrow text-xsm">
                                <svg className="icon" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.468998 0.969059C0.538667 0.899214 0.62143 0.843799 0.712548 0.80599C0.803665 0.76818 0.901347 0.748718 0.999998 0.748718C1.09865 0.748718 1.19633 0.76818 1.28745 0.80599C1.37857 0.843799 1.46133 0.899214 1.531 0.969059L10 9.43956L18.469 0.969059C18.5387 0.899327 18.6215 0.844012 18.7126 0.806273C18.8037 0.768535 18.9014 0.749111 19 0.749111C19.0986 0.749111 19.1963 0.768535 19.2874 0.806273C19.3785 0.844012 19.4613 0.899327 19.531 0.969059C19.6007 1.03879 19.656 1.12157 19.6938 1.21268C19.7315 1.30379 19.7509 1.40144 19.7509 1.50006C19.7509 1.59867 19.7315 1.69633 19.6938 1.78743C19.656 1.87854 19.6007 1.96133 19.531 2.03106L10.531 11.0311C10.4613 11.1009 10.3786 11.1563 10.2874 11.1941C10.1963 11.2319 10.0986 11.2514 10 11.2514C9.90135 11.2514 9.80367 11.2319 9.71255 11.1941C9.62143 11.1563 9.53867 11.1009 9.469 11.0311L0.468998 2.03106C0.399153 1.96139 0.343739 1.87863 0.305929 1.78751C0.26812 1.69639 0.248657 1.59871 0.248657 1.50006C0.248657 1.40141 0.26812 1.30373 0.305929 1.21261C0.343739 1.12149 0.399153 1.03873 0.468998 0.969059Z" fill="currentColor"></path>
                                </svg>
                            </span>}
                                </button>
                            </DropdownToggle>
                            {this.state.premium &&
                                <DropdownMenu className="dropdown-menu dropdown-menu--minw-310">
                                    <div className="py-2 px-3">
                                        <div className="row row-gutter-15">
                                            <div className="col">
                                                <input type="text" className="form-control form-control--bordered mb-2" onChange={this.handleChangeSearch.bind(this, "minebitda_multiple")} value={this.state.fields["minebitda_multiple"]} placeholder="Min" name="minebitda_multiple" />
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="minebitda_multiple1" name="minebitda_multiple"  value="Any Min" onChange={this.handleChangeMinEbitdaMultiple.bind()}/>
                                                        <Label check htmlFor="minebitda_multiple1">
                                                            Any Min
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(0.5, true)}>
                                                        <Input type="radio" id="minebitda_multiple2" name="minebitda_multiple"  value="0.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(0.5, true)}/>
                                                        <Label check htmlFor="minebitda_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(1, true)}>
                                                        <Input type="radio" id="minebitda_multiple3" name="minebitda_multiple"  value="1" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(1, true)}/>
                                                        <Label check htmlFor="minebitda_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(1.5, true)}>
                                                        <Input type="radio" id="minebitda_multiple4" name="minebitda_multiple"  value="1.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(1.5, true)}/>
                                                        <Label check htmlFor="minebitda_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(2, true)}>
                                                        <Input type="radio" id="minebitda_multiple5" name="minebitda_multiple"  value="2" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(2, true)}/>
                                                        <Label check htmlFor="minebitda_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(2.5, true)}>
                                                        <Input type="radio" id="minebitda_multiple6" name="minebitda_multiple"  value="2.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(2.5, true)}/>
                                                        <Label check htmlFor="minebitda_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(3, true)}>
                                                        <Input type="radio" id="minebitda_multiple7" name="minebitda_multiple"  value="3" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(3, true)}/>
                                                        <Label check htmlFor="minebitda_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(3.5, true)}>
                                                        <Input type="radio" id="minebitda_multiple8" name="minebitda_multiple"  value="3.5" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(3.5, true)}/>
                                                        <Label check htmlFor="minebitda_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(4, true)}>
                                                        <Input type="radio" id="minebitda_multiple9" name="minebitda_multiple"  value="4" onChange={this.handleChangeMinEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(4, true)}/>
                                                        <Label check htmlFor="minebitda_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <input onChange={this.handleChangeSearch.bind(this, "maxebitda_multiple")} value={this.state.fields["maxebitda_multiple"]} type="text" className="form-control form-control--bordered mb-2" placeholder="Max" name="maxebitda_multiple" />
                                                <div className="text-center">
                                                    <FormGroup check>
                                                        <Input type="radio" id="maxebitda_multiple1" name="maxebitda_multiple"  value="Any Max" onChange={this.handleChangeMaxEbitdaMultiple.bind()}/>
                                                        <Label check htmlFor="maxebitda_multiple1">
                                                            Any Max
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(0.5, false)}>
                                                        <Input type="radio" id="maxebitda_multiple2" name="maxebitda_multiple"  value="0.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(0.5, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple2">
                                                            0.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(1, false)}>
                                                        <Input type="radio" id="maxebitda_multiple3" name="maxebitda_multiple"  value="1" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(1, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple3">
                                                            1
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(1.5, false)}>
                                                        <Input type="radio" id="maxebitda_multiple4" name="maxebitda_multiple"  value="1.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(1.5, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple4">
                                                            1.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(2, false)}>
                                                        <Input type="radio" id="maxebitda_multiple5" name="maxebitda_multiple"  value="2" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(2, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple5">
                                                            2
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(2.5, false)}>
                                                        <Input type="radio" id="maxebitda_multiple6" name="maxebitda_multiple"  value="2.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(2.5, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple6">
                                                            2.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(3, false)}>
                                                        <Input type="radio" id="maxebitda_multiple7" name="maxebitda_multiple"  value="3" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(3, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple7">
                                                            3
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(3.5, false)}>
                                                        <Input type="radio" id="maxebitda_multiple8" name="maxebitda_multiple"  value="3.5" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(3.5, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple8">
                                                            3.5
                                                        </Label>
                                                    </FormGroup>

                                                    <FormGroup check disabled={!isPriceValueAvailable('ebitda_multiple')(4, false)}>
                                                        <Input type="radio" id="maxebitda_multiple9" name="maxebitda_multiple"  value="4" onChange={this.handleChangeMaxEbitdaMultiple.bind()} disabled={!isPriceValueAvailable('ebitda_multiple')(4, false)}/>
                                                        <Label check htmlFor="maxebitda_multiple9">
                                                            4
                                                        </Label>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            }
                        </UncontrolledDropdown>
                        {(this.state.fields["minebitda_multiple"] || this.state.fields["maxebitda_multiple"]) && <span onClick={() => this.clearMetrics('minebitda_multiple', 'maxebitda_multiple')} className="text-xsm cursor_pointer">
                            <svg style={{ position: 'absolute', top: 11, right: 17, color: '#6D7277' }} className="icon" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.781 1.21897C12.8508 1.28864 12.9062 1.3714 12.944 1.46252C12.9818 1.55363 13.0013 1.65132 13.0013 1.74997C13.0013 1.84862 12.9818 1.9463 12.944 2.03742C12.9062 2.12854 12.8508 2.2113 12.781 2.28097L2.28097 12.781C2.14014 12.9218 1.94913 13.0009 1.74997 13.0009C1.55081 13.0009 1.3598 12.9218 1.21897 12.781C1.07814 12.6401 0.999023 12.4491 0.999023 12.25C0.999023 12.0508 1.07814 11.8598 1.21897 11.719L11.719 1.21897C11.7886 1.14912 11.8714 1.09371 11.9625 1.0559C12.0536 1.01809 12.1513 0.998627 12.25 0.998627C12.3486 0.998627 12.4463 1.01809 12.5374 1.0559C12.6285 1.09371 12.7113 1.14912 12.781 1.21897Z" fill="currentColor"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.21912 1.21897C1.14928 1.28864 1.09386 1.3714 1.05605 1.46252C1.01824 1.55363 0.998779 1.65132 0.998779 1.74997C0.998779 1.84862 1.01824 1.9463 1.05605 2.03742C1.09386 2.12854 1.14928 2.2113 1.21912 2.28097L11.7191 12.781C11.8599 12.9218 12.051 13.0009 12.2501 13.0009C12.4493 13.0009 12.6403 12.9218 12.7811 12.781C12.9219 12.6401 13.0011 12.4491 13.0011 12.25C13.0011 12.0508 12.9219 11.8598 12.7811 11.719L2.28112 1.21897C2.21145 1.14912 2.12869 1.09371 2.03757 1.0559C1.94645 1.01809 1.84877 0.998627 1.75012 0.998627C1.65147 0.998627 1.55379 1.01809 1.46267 1.0559C1.37155 1.09371 1.28879 1.14912 1.21912 1.21897Z" fill="currentColor"></path>
                            </svg>
                        </span>}
                    </div>
                    {/* <!-- Asset sale --> */}
                    <div className="col-6 col-md-4 col-lg-3 mb-2">
                        
                            {/* <label for="customCheck1" style={{ width: '100%', marginBottom: 0 }}>
                            <div className="custom-control custom-checkbox btn-filter btn" style={{ width: '100%' }}>
                                <input type="checkbox" name="asset_sale"  value={this.state.fields.asset_sale}
                                                        checked={this.state.fields.asset_sale}
                                                        onChange={this.handleChangeSearch.bind(this, "asset_sale")} className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label text-sm" for="customCheck1">Asset sale</label>
                            </div>    
                            </label> */}
                            <div className="text-sm text-center">
                                <a style={{ borderBottom: '1px solid' }} href={'/home'}>Clear All</a>
                            </div>
                        
                    </div>
                </div>
                
                </div>
            </section>
                <Modal isOpen={this.state.premiumModal} toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>
                    <ModalHeader toggle={() => this.setState({ premiumModal: !this.state.premiumModal })}>BizNexus</ModalHeader>
                    <ModalBody>
                        Upgrade your subscription to search by multiples.
                    </ModalBody>
                    <ModalFooter>
                        <a href="/user/subscription" className="btn btn-primary">Learn More</a>
                    </ModalFooter>
                </Modal>
                <Modal className="new-modal" isOpen={this.state.showIndustryFilter} toggle={() => this.setState({ showIndustryFilter: !this.state.showIndustryFilter })}>
                    <ModalHeader toggle={() => this.setState({ showIndustryFilter: !this.state.showIndustryFilter })}>Select Target Industries</ModalHeader>
                    <ModalBody>
                        <IndustryFilter
                            industriesList={this.state.categorylist}
                            selectedIndustries={this.state.brokerIndustry}
                            updateIndustries={this.onUpdateBrokerIndustry}
                            onRemoveIndustry={this.onRemoveBrokerIndustry}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary-blue"
                            className="toggle-filter-button"
                            type="button"
                            onClick={() => this.setState(prevState => ({ showIndustryFilter: !prevState.showIndustryFilter }))}
                        >
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        )
    }
}


export default connect()(Console);
