import {USER_TYPE} from "./userTypes";

const buyerLinks = [
    {
        title: 'Dashboard',
        link: '/home',
        icon: 'd-none d-xl-inline-block fas fa-rss profile-card__nav-link',
    },
    {
        title: 'My Targets',
        link: '/user/favorites',
        icon: 'd-none d-xl-inline-block far fa-heart',
    },
    {
        title: 'My Company',
        link: '/user/listings',
        icon: 'd-none d-xl-inline-block fa-regular fa-rectangle-list',
    },
    {
        title: 'Pricing',
        link: 'https://www.biznexus.com/pricing',
        icon: 'd-none d-xl-inline-block fa-solid fa-tags',
    },
    {
        title: 'Resources',
        options: [
            {
                title: 'Concierge Origination',
                link: 'https://www.biznexus.com/biznexus-concierge-for-buyers'
            },
            {
                title: 'Acquisition Financing',
                link: 'https://www.biznexus.com/acquisition-financing'
            },
            {
                title: 'Due Diligence',
                link: 'https://www.biznexus.com/due-diligence'
            },
            {
                title: 'Tech Diligence',
                link: 'https://www.fastcto.com/services/due-diligence'
            },
        ]
    },
]

const sellerLinks = [
    {
        title: 'Dashboard',
        link: '/home',
        icon: 'd-none d-xl-inline-block fas fa-rss profile-card__nav-link',
    },
    {
        title: 'My Targets',
        link: '/user/favorites',
        icon: 'd-none d-xl-inline-block far fa-heart',
    },
    {
        title: 'My Company',
        link: '/user/listings',
        icon: 'd-none d-xl-inline-block fa-regular fa-rectangle-list',
    },
    {
        title: 'Pricing',
        link: 'https://www.biznexus.com/pricing',
        icon: 'd-none d-xl-inline-block fa-solid fa-tags',
    },
    {
        title: 'Resources',
        options: [
            {
                title: 'Concierge Origination',
                link: 'https://www.biznexus.com/biznexus-concierge-for-buyers'
            },
            {
                title: 'Acquisition Financing',
                link: 'https://www.biznexus.com/acquisition-financing'
            },
            {
                title: 'Due Diligence',
                link: 'https://www.biznexus.com/due-diligence'
            },
            {
                title: 'Tech Diligence',
                link: 'https://www.fastcto.com/services/due-diligence'
            },
        ]
    },
]

const brokerLinks = [
    {
        title: 'Dashboard',
        link: '/home',
        icon: 'd-none d-xl-inline-block fas fa-rss profile-card__nav-link',
    },
    {
        title: 'Match Preferences',
        link: '/user/favorites/preferences',
        icon: 'd-none d-xl-inline-block far fa-heart',
    },
    {
        title: 'My Deals',
        link: '/user/listings',
        icon: 'd-none d-xl-inline-block fa-regular fa-rectangle-list',
    },
    {
        title: 'Resources',
        options: [
            {
                title: 'Concierge Origination',
                link: 'https://www.biznexus.com/biznexus-concierge-for-providers',
            },
            {
                title: 'Boost My Brand',
                link: '/user/ads'
            },
            {
                title: 'Schedule A Demo',
                link: 'https://www.biznexus.com/demo'
            },
        ]
    },
]

const providerLinks = [
    {
        title: 'Dashboard',
        link: '/home',
        icon: 'd-none d-xl-inline-block fas fa-rss profile-card__nav-link',
    },
    {
        title: 'My Targets',
        link: '/user/favorites/preferences',
        icon: 'd-none d-xl-inline-block far fa-heart',
    },
    {
        title: 'Pricing',
        link: 'https://www.biznexus.com/pricing',
        icon: 'd-none d-xl-inline-block fa-solid fa-tags',
    },
    {
        title: 'Lead Generation',
        link: 'https://www.biznexus.com/biznexus-concierge-for-providers',
    },
    {
        title: 'Promote My Brand',
        options: [
            {
                title: 'Boost My Profile',
                link: '/user/ads'
            },
            {
                title: 'Advertise My Brand',
                link: 'https://www.biznexus.com/advertise'
            },
            {
                title: 'Join The Advisor Panel',
                link: 'https://www.biznexus.com/biznexus-advisor-panel'
            },
            {
                title: 'Schedule a Strategy Session',
                link: 'https://calendly.com/biznexus-team/biznexus-advertising'
            },
        ]
    },
]

const headerLinks = {
    [USER_TYPE.BUYER]: buyerLinks,
    [USER_TYPE.SELLER]: sellerLinks,
    [USER_TYPE.BROKER]: brokerLinks,
    [USER_TYPE.PROVIDER]: providerLinks,
}

export {
    headerLinks
}
